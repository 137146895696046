import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { IconButton, withTheme } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import {
  hideInternalDrawer,
  displayParticipantListInternalDrawer,
  displayChatInternalDrawer,
  displayVideoInternalDrawer,
  displaySettingsInternalDrawer,
  displayConferencePreferencesInternalDrawer,
  displayTranscriptInternalDrawer,
  setMainRoom,
  resetMainRoom,
  setSelfViewOn
} from "../../actions";
import SvgIcon from "../Icons/SvgIcon";
import { InternalDrawerState } from "../layouts/internalDrawerStates";
import { injectIntl } from "react-intl";
import {
  isHoldStateApplicable,
  getIntl,
  TitleNewLine,
  isVideoEnabled,
  isHost
} from "../../utils";
import classNames from "classnames";

const styles = theme => ({
  root: {
    gridColumn: "1",
    gridRow: "2",
    msGridColumn: "1",
    msGridRow: "2",
    backgroundColor: theme.colors.sideBarBackgroundColor,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    zIndex: 1
  },
  svg: {
    width: "100%"
  },
  expandSvg: {
    height: "1em"
  },
  badgeRoot: {
    width: "100%",
    display: "inline",
    position: "relative",
    verticalAlign: "middle"
  },
  badgeColor: {
    backgroundColor: theme.colors.errorMainColor,
    color: theme.colors.errorTextColor
  },
  transparent: {
    opacity: 0.95
  },
  iconButton: {
    width: "100%"
  }
});

class LeftBar extends Component {
  handleParticipantList = () => {
    const { participantListPopoutWindow, internalDrawer, session } = this.props;

    if (participantListPopoutWindow.windowName) {
      window.open("", participantListPopoutWindow.windowName);
    } else {
      if (internalDrawer === InternalDrawerState.PARTICIPANT_LIST) {
        this.props.hideInternalDrawer();
      } else {
        if (!session.sharingActive) {
          this.props.setMainRoom();
        }
        this.props.displayParticipantListInternalDrawer();
      }
    }
  };

  handleChat = () => {
    const { popoutWindows, internalDrawer, session } = this.props;

    if (popoutWindows.chatWindowName) {
      window.open("", popoutWindows.chatWindowName);
    } else {
      if (internalDrawer === InternalDrawerState.CHAT) {
        this.props.hideInternalDrawer();
      } else {
        if (!session.sharingActive) {
          this.props.setMainRoom();
        }
        this.props.displayChatInternalDrawer();
      }
    }
  };

  handleVideo = () => {
    const { videoPopoutWindow, internalDrawer, session } = this.props;
    if (videoPopoutWindow.windowName) {
      window.open("", videoPopoutWindow.windowName);
      this.props.resetMainRoom();
    } else {
      if (internalDrawer === InternalDrawerState.VIDEO) {
        if (!session.sharingActive) {
          this.props.setMainRoom();
        }
        this.props.hideInternalDrawer();
      } else {
        this.props.displayVideoInternalDrawer();
        this.props.resetMainRoom();
      }
    }
  };

  handleSettings = () => {
    const { internalDrawer, session } = this.props;
    if (internalDrawer === InternalDrawerState.SETTINGS) {
      this.props.hideInternalDrawer();
    } else {
      if (!session.sharingActive) {
        this.props.setMainRoom();
      }
      this.props.displaySettingsInternalDrawer();
    }
  };

  handleConferencePreferences = () => {
    const { internalDrawer, session } = this.props;
    if (internalDrawer === InternalDrawerState.CONFERENCE_PREFERENCES) {
      this.props.hideInternalDrawer();
    } else {
      if (!session.sharingActive) {
        this.props.setMainRoom();
      }
      this.props.displayConferencePreferencesInternalDrawer();
    }
  };

  handleTranscript = () => {
    const { popoutWindows, internalDrawer, session } = this.props;

    if (popoutWindows.transcriptWindowName) {
      window.open("", popoutWindows.transcriptWindowName);
    } else if (internalDrawer === InternalDrawerState.TRANSCRIPT) {
      this.props.hideInternalDrawer();
    } else {
      if (!session.sharingActive) {
        this.props.setMainRoom();
      }
      this.props.displayTranscriptInternalDrawer();
    }
  };

  handleSelfViewOn = () => {
    this.props.setSelfViewOn();
    if (this.props.internalDrawer === InternalDrawerState.HIDDEN) {
      this.props.displayParticipantListInternalDrawer();
    }
  };

  render() {
    const {
      classes,
      displayContent,
      participantListPopoutWindow,
      popoutWindows,
      videoPopoutWindow,
      internalDrawer,
      participants,
      unreadChats,
      conference,
      session,
      intl
    } = this.props;

    const unreadChatCount = unreadChats.unreadCount;
    const waitingRoomPartyCount = participants.waitingRoomParties.length;

    const participantListActive =
      participantListPopoutWindow.windowName ||
      internalDrawer === InternalDrawerState.PARTICIPANT_LIST;

    const chatActive =
      popoutWindows.chatWindowName ||
      internalDrawer === InternalDrawerState.CHAT;

    const videoActive =
      videoPopoutWindow.windowName ||
      internalDrawer === InternalDrawerState.VIDEO;

    const settingsActive = internalDrawer === InternalDrawerState.SETTINGS;

    const conferencePreferencesActive =
      internalDrawer === InternalDrawerState.CONFERENCE_PREFERENCES;

    const transcriptActive =
      popoutWindows.transcriptWindowName ||
      internalDrawer === InternalDrawerState.TRANSCRIPT;

    const holdState = isHoldStateApplicable(conference, session);

    const participantsIcon = participantListActive ? (
      <Fragment>
        <svg className={classes.svg} viewBox="0 0 1 1">
          <SvgIcon iconName="circle" viewBox="none" />
          <SvgIcon iconName="participantList" color="inactive" viewBox="none" />
        </svg>
      </Fragment>
    ) : (
      <Fragment>
        <svg className={classes.svg} viewBox="0 0 1 1">
          <SvgIcon iconName="circle" viewBox="none" />
          <SvgIcon iconName="participantList" color="sideBar" viewBox="none" />
        </svg>
      </Fragment>
    );

    const participantsIconWithBadge = (
      <Badge
        badgeContent={waitingRoomPartyCount}
        max={99}
        color="primary"
        classes={{
          root: classes.badgeRoot,
          colorPrimary: classes.badgeColor
        }}
      >
        {participantsIcon}
      </Badge>
    );

    const chatIcon = chatActive ? (
      <svg className={classes.svg} viewBox="0 0 1 1">
        <SvgIcon iconName="circle" viewBox="none" />
        <SvgIcon iconName="chat" color="inactive" viewBox="none" />
      </svg>
    ) : (
      <svg className={classes.svg} viewBox="0 0 1 1">
        <SvgIcon iconName="circle" viewBox="none" />
        <SvgIcon iconName="chat" color="sideBar" viewBox="none" />
      </svg>
    );

    const chatIconWithBadge = (
      <Badge
        badgeContent={unreadChatCount}
        max={99}
        color="primary"
        classes={{
          root: classes.badgeRoot,
          colorPrimary: classes.badgeColor
        }}
      >
        {chatIcon}
      </Badge>
    );

    const videoIcon = holdState ? (
      <svg className={classes.svg} viewBox="0 0 1 1">
        <SvgIcon iconName="video" color="inactive" viewBox="none" />
      </svg>
    ) : videoActive ? (
      <svg className={classes.svg} viewBox="0 0 1 1">
        <SvgIcon iconName="circle" viewBox="none" />
        <SvgIcon iconName="video" color="inactive" viewBox="none" />
      </svg>
    ) : (
      <svg className={classes.svg} viewBox="0 0 1 1">
        <SvgIcon iconName="circle" viewBox="none" />
        <SvgIcon iconName="video" color="sideBar" viewBox="none" />
      </svg>
    );

    const settingsIcon = settingsActive ? (
      <svg className={classes.svg} viewBox="0 0 1 1">
        <SvgIcon iconName="circle" viewBox="none" />
        <SvgIcon iconName="controls" color="inactive" viewBox="none" />
      </svg>
    ) : (
      <svg className={classes.svg} viewBox="0 0 1 1">
        <SvgIcon iconName="circle" viewBox="none" />
        <SvgIcon iconName="controls" color="sideBar" viewBox="none" />
      </svg>
    );

    const transcriptIcon = transcriptActive ? (
      <svg className={classes.svg} viewBox="0 0 1 1">
        <SvgIcon iconName="circle" viewBox="none" />
        <SvgIcon iconName="transcript" color="inactive" viewBox="none" />
      </svg>
    ) : (
      <svg className={classes.svg} viewBox="0 0 1 1">
        <SvgIcon iconName="circle" viewBox="none" />
        <SvgIcon iconName="transcript" color="sideBar" viewBox="none" />
      </svg>
    );

    const conferencePreferencesIcon = conferencePreferencesActive ? (
      <svg className={classes.svg} viewBox="0 0 1 1">
        <SvgIcon iconName="circle" viewBox="none" />
        <SvgIcon iconName="preferences" color="inactive" viewBox="none" />
      </svg>
    ) : (
      <svg className={classes.svg} viewBox="0 0 1 1">
        <SvgIcon iconName="circle" viewBox="none" />
        <SvgIcon iconName="preferences" color="sideBar" viewBox="none" />
      </svg>
    );

    const selfViewIcon = (
      <svg className={classes.expandSvg} viewBox="0 0 1 1">
        <SvgIcon iconName="expand" viewBox="none" />
      </svg>
    );

    return (
      <div
        className={classNames(
          classes.root,
          session.fullScreenMode && classes.transparent
        )}
      >
        {displayContent && (
          <Fragment>
            <div>
              {window.CtxAppConfigurations.customer !== "TCAD" && (
                <>
                  {(!session.disableParticipantListForGuests ||
                    isHost(session)) && (
                    <IconButton
                      className={classes.iconButton}
                      onClick={this.handleParticipantList}
                      title={
                        intl.formatMessage(getIntl("participantList")) +
                        TitleNewLine +
                        (participantListPopoutWindow.windowName
                          ? intl.formatMessage(
                              getIntl("currentlyInPopoutWindow")
                            ) +
                            TitleNewLine +
                            intl.formatMessage(getIntl("clickToFocusWindow"))
                          : internalDrawer ===
                            InternalDrawerState.PARTICIPANT_LIST
                          ? intl.formatMessage(getIntl("currentlyInDrawer")) +
                            TitleNewLine +
                            intl.formatMessage(getIntl("clickToCollapseDrawer"))
                          : intl.formatMessage(getIntl("currentlyInactive")) +
                            TitleNewLine +
                            intl.formatMessage(getIntl("clickToShowInDrawer")))
                      }
                    >
                      {participantsIconWithBadge}
                    </IconButton>
                  )}
                  <IconButton
                    className={classes.iconButton}
                    onClick={this.handleChat}
                    title={
                      intl.formatMessage(getIntl("chat")) +
                      TitleNewLine +
                      (popoutWindows.chatWindowName
                        ? intl.formatMessage(
                            getIntl("currentlyInPopoutWindow")
                          ) +
                          TitleNewLine +
                          intl.formatMessage(getIntl("clickToFocusWindow"))
                        : internalDrawer === InternalDrawerState.CHAT
                        ? intl.formatMessage(getIntl("currentlyInDrawer")) +
                          TitleNewLine +
                          intl.formatMessage(getIntl("clickToCollapseDrawer"))
                        : intl.formatMessage(getIntl("currentlyInactive")) +
                          TitleNewLine +
                          intl.formatMessage(getIntl("clickToShowInDrawer")))
                    }
                  >
                    {chatIconWithBadge}
                  </IconButton>
                </>
              )}
              {isVideoEnabled(session.videoConfig) && (
                <IconButton
                  className={classes.iconButton}
                  disabled={holdState}
                  onClick={this.handleVideo}
                  title={
                    intl.formatMessage(getIntl("video")) +
                    TitleNewLine +
                    (holdState
                      ? intl.formatMessage(getIntl("controlDisabled"))
                      : videoPopoutWindow.windowName
                      ? intl.formatMessage(getIntl("currentlyInPopoutWindow")) +
                        TitleNewLine +
                        intl.formatMessage(getIntl("clickToFocusWindow"))
                      : internalDrawer === InternalDrawerState.VIDEO
                      ? intl.formatMessage(getIntl("currentlyInDrawer")) +
                        TitleNewLine +
                        intl.formatMessage(getIntl("clickToCollapseDrawer"))
                      : intl.formatMessage(getIntl("currentlyInactive")) +
                        TitleNewLine +
                        intl.formatMessage(getIntl("clickToShowInDrawer")))
                  }
                  style={holdState ? { pointerEvents: "auto" } : undefined}
                >
                  {videoIcon}
                </IconButton>
              )}
              {false && (
                <IconButton
                  className={classes.iconButton}
                  onClick={this.handleSettings}
                  title={
                    intl.formatMessage(getIntl("userControls")) +
                    TitleNewLine +
                    (internalDrawer === InternalDrawerState.SETTINGS
                      ? intl.formatMessage(getIntl("currentlyInDrawer")) +
                        TitleNewLine +
                        intl.formatMessage(getIntl("clickToCollapseDrawer"))
                      : intl.formatMessage(getIntl("currentlyInactive")) +
                        TitleNewLine +
                        intl.formatMessage(getIntl("clickToShowInDrawer")))
                  }
                >
                  {settingsIcon}
                </IconButton>
              )}
              {window.CtxAppConfigurations.transcriptionEnabled &&
                session.transcriptionEnabled && (
                  <IconButton
                    className={classes.iconButton}
                    onClick={this.handleTranscript}
                    title={
                      intl.formatMessage(getIntl("transcript")) +
                      TitleNewLine +
                      (popoutWindows.transcriptWindowName
                        ? intl.formatMessage(
                            getIntl("currentlyInPopoutWindow")
                          ) +
                          TitleNewLine +
                          intl.formatMessage(getIntl("clickToFocusWindow"))
                        : internalDrawer === InternalDrawerState.TRANSCRIPT
                        ? intl.formatMessage(getIntl("currentlyInDrawer")) +
                          TitleNewLine +
                          intl.formatMessage(getIntl("clickToCollapseDrawer"))
                        : intl.formatMessage(getIntl("currentlyInactive")) +
                          TitleNewLine +
                          intl.formatMessage(getIntl("clickToShowInDrawer")))
                    }
                  >
                    {transcriptIcon}
                  </IconButton>
                )}
              {isHost(session) &&
                isVideoEnabled(session.videoConfig) &&
                window.CtxAppConfigurations.experimentalModeEnabled && (
                  <IconButton
                    className={classes.iconButton}
                    onClick={this.handleConferencePreferences}
                    title={
                      intl.formatMessage(getIntl("conferencePreferences")) +
                      TitleNewLine +
                      (conferencePreferencesActive
                        ? intl.formatMessage(getIntl("currentlyInDrawer")) +
                          TitleNewLine +
                          intl.formatMessage(getIntl("clickToCollapseDrawer"))
                        : intl.formatMessage(getIntl("currentlyInactive")) +
                          TitleNewLine +
                          intl.formatMessage(getIntl("clickToShowInDrawer")))
                    }
                  >
                    {conferencePreferencesIcon}
                  </IconButton>
                )}
            </div>
            {isVideoEnabled(session.videoConfig) &&
              window.CtxAppConfigurations.experimentalModeEnabled &&
              (!session.drawerSelfViewEnabled ||
                internalDrawer === InternalDrawerState.HIDDEN) && (
                <IconButton
                  className={classes.iconButton}
                  onClick={this.handleSelfViewOn}
                  title={
                    intl.formatMessage(getIntl("selfView")) +
                    TitleNewLine +
                    intl.formatMessage(getIntl("currentlyInactive")) +
                    TitleNewLine +
                    intl.formatMessage(getIntl("clickToShowInDrawer"))
                  }
                >
                  {selfViewIcon}
                </IconButton>
              )}
          </Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({
  internalDrawer,
  participantListPopoutWindow,
  popoutWindows,
  videoPopoutWindow,
  participants,
  unreadChats,
  conference,
  session
}) => ({
  internalDrawer,
  participantListPopoutWindow,
  popoutWindows,
  videoPopoutWindow,
  participants,
  unreadChats,
  conference,
  session
});

const mapDispatchToProps = dispatch => ({
  hideInternalDrawer: () => dispatch(hideInternalDrawer()),
  displayParticipantListInternalDrawer: () =>
    dispatch(displayParticipantListInternalDrawer()),
  displayChatInternalDrawer: () => dispatch(displayChatInternalDrawer()),
  displayVideoInternalDrawer: () => dispatch(displayVideoInternalDrawer()),
  displaySettingsInternalDrawer: () =>
    dispatch(displaySettingsInternalDrawer()),
  displayConferencePreferencesInternalDrawer: () =>
    dispatch(displayConferencePreferencesInternalDrawer()),
  displayTranscriptInternalDrawer: () =>
    dispatch(displayTranscriptInternalDrawer()),
  setMainRoom: () => dispatch(setMainRoom()),
  resetMainRoom: () => dispatch(resetMainRoom()),
  setSelfViewOn: () => dispatch(setSelfViewOn())
});

export default withTheme(
  withStyles(styles)(
    injectIntl(connect(mapStateToProps, mapDispatchToProps)(LeftBar))
  )
);
