import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { SettingsMenu } from ".";
import { getIntl, isMobileOrTablet } from "../../utils";
import SvgIcon from "../Icons/SvgIcon";
import { injectIntl } from "react-intl";
import { IconButton } from "@material-ui/core";
import { makeResponsive } from "../reusable/ResponsiveContainer";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { hideInternalDrawer } from "../../actions";

const headerHeight = "32px";
const styles = theme => ({
  layout: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.colors.popoverBackgroundColor,
    color: theme.colors.primaryTextColor,
    border: "none",
    paddingLeft: "5px",
    paddingRight: "5px"
  },
  drawerHeader: {
    width: "100%",
    textAlign: "right",
    height: headerHeight,
    padding: "3px 3px 3px 0px"
  },
  closeHeaderButton: {
    fontSize: "1.2em",
    padding: "8px 8px 8px 8px"
  },
  closeHeaderIcon: {
    width: "0.7em"
  }
});

class SettingsMenuResponsive extends Component {
  getDrawerHeader = () => {
    const { classes, intl } = this.props;
    return (
      <div className={classes.drawerHeader}>
        <IconButton
          className={classes.closeHeaderButton}
          disabled={false}
          onClick={() => this.handleCloseWindow()}
          title={intl.formatMessage(getIntl("close"))}
        >
          <SvgIcon iconName="close" className={classes.closeHeaderIcon} />
        </IconButton>
      </div>
    );
  };

  handleCloseWindow = () => {
    this.props.hideInternalDrawer();
  };

  render() {
    return (
      <Fragment>
        {!isMobileOrTablet() && this.getDrawerHeader()}
        <SettingsMenu {...this.props} />
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  hideInternalDrawer: () => dispatch(hideInternalDrawer())
});

export default makeResponsive(
  withTheme(
    withStyles(styles)(
      injectIntl(connect(null, mapDispatchToProps)(SettingsMenuResponsive))
    )
  )
);
