import React, { Fragment } from "react";
import SvgIcon from "../Icons/SvgIcon";
import { IconButton } from "@material-ui/core";
import LocalizedText from "../reusable/LocalizedText";
import { isApplicationAvailable, isSupportedEnvironment } from "../../utils";
import TextButton from "../reusable/TextButton";
import { getIntl, TitleNewLine } from "../../utils";
import CTXBaseLeftPopper from "../reusable/CTXBaseLeftPopper";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useEffect, useState, useRef } from "react";
import Logger from "../../Logger";

const logger = new Logger("DataSharingSettings");

const useDataSharingSettingsStyles = makeStyles(
  {
    mainContent: {
      display: "flex",
      flexDirection: "column",
      width: "400px"
    }
  },
  { name: "DataSharingSettings" }
);

export default function DataSharingSettings() {
  const classes = useDataSharingSettingsStyles();

  return (
    <div className={classes.mainContent}>
      <DataSharingApplication />
    </div>
  );
}

const useDataSharingApplicationStyles = makeStyles(
  theme => ({
    inputBox: {
      borderBottom: `2px solid ${theme.colors.secondaryMainColor}`,
      color: theme.colors.primaryTextColor,
      textAlign: "left"
    },
    svgIcon: {
      width: "1.75em",
      padding: "3px"
    },
    flexContainer: {
      display: "flex",
      flexDirection: "column",
      width: "400px"
    },
    flexRowBox: {
      display: "flex",
      flexDirection: "row",
      paddingTop: "15px",
      alignItems: "center"
    },
    flexItemSmall: {
      flexGrow: 1
    },
    flexItemLarge: {
      flexGrow: 4,
      width: "95%"
    },
    primaryTextColor: {
      color: theme.colors.primaryTextColor
    },
    secondaryMainColor: {
      color: theme.colors.secondaryMainColor
    },
    pluginContainer: {
      width: "65px",
      height: "65px",
      paddingTop: "10px",
      alignSelf: "center"
    }
  }),
  { name: "DataSharingApplication" }
);

function DataSharingApplication() {
  const classes = useDataSharingApplicationStyles();
  const intl = useIntl();
  const iconRef = useRef();

  const [anchorEl, setAnchorEl] = useState(null);

  const [pluginOutOfDate, setPluginOutOfDate] = useState(false);

  const [pluginPopperExpansion, setPluginPopperExpansion] = useState(false);

  const extensionAvailable = useSelector(
    state => state.session.extensionAvailable
  );

  const open = anchorEl != null && pluginPopperExpansion;

  const supportedEnvironment = isSupportedEnvironment();
  const extensionAndApplicationAvailable =
    extensionAvailable && isApplicationAvailable();

  useEffect(() => {
    setAnchorEl(iconRef.current);
  }, []);

  function closePopper() {
    setPluginPopperExpansion(false);
    setPluginOutOfDate(false);
  }

  function handleClickPopper() {
    if (pluginPopperExpansion) return;
    setPluginPopperExpansion(true);
    if (extensionAvailable) {
      if (isApplicationAvailable()) {
        setPluginOutOfDate(false);
      } else {
        if (
          window.gPluginVersion != null &&
          !window.DoubleCheckVersionOk(
            window.CtxAppConfigurations.dataSharingAppMinVersion.replace(
              /,/g,
              "."
            ),
            window.gPluginVersion,
            window.CtxAppConfigurations.dataSharingAppMaxVersion.replace(
              /,/g,
              "."
            )
          )
        ) {
          logger.debug("Plugin is out of date.");
          window.disconnectSharingPlugin();
          window.downloadPlugin(true);
          setPluginOutOfDate(true);
        } else {
          logger.debug("Plugin not installed. Opening PluginPopper!!");
          window.downloadPlugin();
          setPluginOutOfDate(false);
        }
      }
    } else {
      logger.debug("Extension not found. Opening PluginPopper!!");
      setPluginOutOfDate(false);
    }
  }

  return (
    <div className={classes.flexContainer}>
      <div className={classes.flexRowBox}>
        <div className={classes.flexItemSmall}>
          <SvgIcon
            iconName="remoteControl"
            color={supportedEnvironment ? "active" : "inactive"}
            className={classes.svgIcon}
          />
        </div>
        <div className={classes.flexItemLarge}>
          <LocalizedText
            value={
              supportedEnvironment
                ? extensionAndApplicationAvailable
                  ? "sharingRemoteControlAvailable"
                  : "sharingRemoteControlSetupRequired"
                : "unsupportedEnvironment"
            }
            variant="subtitle1"
            className={classNames(
              classes.inputBox,
              supportedEnvironment
                ? classes.primaryTextColor
                : classes.secondaryMainColor
            )}
          />
        </div>
      </div>

      {supportedEnvironment && !extensionAndApplicationAvailable && (
        <div className={classes.pluginContainer}>
          <IconButton
            id="pluginPopperButton"
            className={classes.popperIconButton}
            onClick={handleClickPopper}
            ref={iconRef}
            title={
              intl.formatMessage(getIntl("sharingRemoteControlSetup")) +
              TitleNewLine +
              intl.formatMessage(getIntl("clickToSetUpSharingRemoteControl"))
            }
          >
            <SvgIcon iconName="download" color="active" />
          </IconButton>

          <CTXBaseLeftPopper
            open={open}
            anchorEl={anchorEl}
            closePopper={closePopper}
          >
            <PopperContents
              extensionAvailable={extensionAvailable}
              pluginOutOfDate={pluginOutOfDate}
            />
          </CTXBaseLeftPopper>
        </div>
      )}
    </div>
  );
}

const usePopperContentsStyles = makeStyles(
  theme => ({
    textWithMargin: {
      color: theme.colors.primaryTextColor,
      alignSelf: "flex-start",
      marginTop: "5px",
      marginBottom: "5px"
    },
    marginTop: {
      marginTop: "10px"
    }
  }),
  { name: "PopperContents" }
);

function PopperContents({ extensionAvailable, pluginOutOfDate }) {
  const classes = usePopperContentsStyles();

  function showExtensionPopUp() {
    window.open(
      "https://chrome.google.com/webstore/detail/connectnow/dddkajkghgbmbpnipcacfflijcmfobnp?hl=en-US&gl=US"
    );
  }

  return extensionAvailable ? (
    pluginOutOfDate ? (
      <Fragment>
        <LocalizedText
          className={classes.textWithMargin}
          value="pluginOutOfDate"
        />
        <LocalizedText
          className={classes.textWithMargin}
          value="setUpMSIAndClickToComplete"
        />
        <TextButton
          className={classes.marginTop}
          onClick={() => {
            window.location.reload();
          }}
        >
          <LocalizedText value="clickToComplete" />
        </TextButton>
      </Fragment>
    ) : (
      <Fragment>
        <LocalizedText className={classes.textWithMargin} value="setUpMSI" />
      </Fragment>
    )
  ) : (
    <TextButton className={classes.marginTop} onClick={showExtensionPopUp}>
      <LocalizedText value="setUpExtension" />
    </TextButton>
  );
}
