import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { hideConferencePreferencesMenu } from "../../actions";
import CTXBaseWindow from "../reusable/CTXBaseWindow";
import ConferencePreferences from "../menus/ConferencePreferences";

const useStyles = makeStyles(
  {
    container: {
      display: "flex",
      zIndex: 11,
      width: "auto",
      height: "auto",
      alignSelf: "center",
      position: "absolute"
    },
    padding: {
      padding: 0
    }
  },
  { name: "ConferencePreferencesMenu" }
);

export default function ConferencePreferencesMenu() {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <div className={classes.container}>
      <CTXBaseWindow
        classes={{ mainContent: classes.padding }}
        component={<ConferencePreferences menu />}
        overrideHandleClose={() => dispatch(hideConferencePreferencesMenu())}
        disableClickAwayClose={true}
        disableContainerStyle
      />
    </div>
  );
}
