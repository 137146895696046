import React, { Component } from "react";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { hideNotificationWindow } from "./";
import Snackbar from "@material-ui/core/Snackbar";
import { NotificationWindowState } from "./NotificationWindow";
import PropTypes from "prop-types";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import classNames from "classnames";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";
import { IconButton, Typography } from "@material-ui/core";
import SvgIcon from "../Icons/SvgIcon";
import { getIntl } from "../../utils";

export const NotificationLevel = {
  SUCCESS: "SUCCESS",
  WARNING: "WARNING",
  ERROR: "ERROR",
  INFO: "INFO"
};

export const NotificationType = {
  GENERAL: "GENERAL",
  COPY_LINK: "COPY_LINK",
  DISABLE_PRESENTER_MODE: "DISABLE_PRESENTER_MODE",
  ENABLE_PRESENTER_MODE: "ENABLE_PRESENTER_MODE",
  STOP_SCREEN_SHARE: "STOP_SCREEN_SHARE",
  HOST_FORCE_REMOVAL: "HOST_FORCE_REMOVAL",
  CONFERENCE_END: "CONFERENCE_END",
  GLOBAL_THEME_UPDATED: "GLOBAL_THEME_UPDATED",
  THEME_PROPERTIES_UPDATED: "THEME_PROPERTIES_UPDATED",
  PERSISTENT_SPACE_THEME_UPDATED: "PERSISTENT_SPACE_THEME_UPDATED",
  PERSISTENT_SPACE_SETTINGS_UPDATED: "PERSISTENT_SPACE_SETTINGS_UPDATED",
  THEME_ADDED: "THEME_ADDED",
  THEME_NAME_EMPTY: "THEME_NAME_EMPTY",
  THEME_DUPLICATE: "THEME_DUPLICATE",
  FILE_UPLOADED: "FILE_UPLOADED",
  PARTY_IN_WAITING_ROOM: "PARTY_IN_WAITING_ROOM",
  AUDIO_INPUT_DEVICE: "AUDIO_INPUT_DEVICE",
  AUDIO_INPUT_DEVICE_PERMISSION: "AUDIO_INPUT_DEVICE_PERMISSION",
  REMOTE_CONTROL_ENABLED: "REMOTE_CONTROL_ENABLED",
  REMOTE_CONTROL_DISABLED: "REMOTE_CONTROL_DISABLED",
  CONNECTION_ERROR: "CONNECTION_ERROR"
};

const styles = theme => ({
  root: {
    marginBottom: "10px"
  },
  success: {
    backgroundColor: theme.colors.notificationSuccessColor
  },
  error: {
    backgroundColor: theme.colors.notificationErrorColor
  },
  warning: {
    backgroundColor: theme.colors.notificationWarningColor
  },
  info: {
    backgroundColor: theme.colors.notificationInfoColor
  },
  messageSpan: {
    display: "flex",
    alignItems: "center"
  },
  messageIconVariant: {
    marginRight: "8px"
  },
  closeNotificationIcon: {
    width: "0.5em"
  }
});

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

class SnackbarNotification extends Component {
  getCloseButtonControls = () => {
    const { classes } = this.props;
    return (
      <IconButton onClick={this.props.hideNotificationWindow}>
        <SvgIcon iconName="close" className={classes.closeNotificationIcon} />
      </IconButton>
    );
  };

  getSnackbarMessageControls = (nType, nLevel) => {
    const { classes, variant, intl, notification } = this.props;
    const MessageVariantIcon = variantIcon[variant];

    let snackbarMessage;

    switch (nType) {
      case NotificationType.GENERAL:
        break;
      case NotificationType.COPY_LINK:
        if (nLevel === NotificationLevel.SUCCESS) {
          snackbarMessage = intl.formatMessage(getIntl("linkCopied"));
        } else if (nLevel === NotificationLevel.ERROR) {
          snackbarMessage = intl.formatMessage(getIntl("copyLinkFailure"));
        }
        break;
      case NotificationType.DISABLE_PRESENTER_MODE:
        if (nLevel === NotificationLevel.WARNING) {
          const { users } = this.props;
          const { senderUserId } = notification.map;

          let senderUser = users[senderUserId];

          snackbarMessage =
            senderUser.username +
            " " +
            intl.formatMessage(getIntl("disablePresenterMode"));
        } else if (nLevel === NotificationLevel.ERROR) {
          snackbarMessage = intl.formatMessage(getIntl("unknownError"));
        }
        break;
      case NotificationType.ENABLE_PRESENTER_MODE:
        if (nLevel === NotificationLevel.SUCCESS) {
          const { users } = this.props;
          const { senderUserId } = notification.map;

          let senderUser = users[senderUserId];

          snackbarMessage =
            senderUser.username +
            " " +
            intl.formatMessage(getIntl("enablePresenterMode"));
        } else if (nLevel === NotificationLevel.ERROR) {
          snackbarMessage = intl.formatMessage(getIntl("unknownError"));
        }
        break;
      case NotificationType.STOP_SCREEN_SHARE:
        if (nLevel === NotificationLevel.WARNING) {
          const { users } = this.props;
          const { senderUserId } = notification.map;

          let senderUser = users[senderUserId];

          snackbarMessage =
            senderUser.username +
            " " +
            intl.formatMessage(getIntl("stopYourScreenShare"));
        } else if (nLevel === NotificationLevel.ERROR) {
          snackbarMessage = intl.formatMessage(getIntl("unknownError"));
        }
        break;
      case NotificationType.HOST_FORCE_REMOVAL:
        snackbarMessage = intl.formatMessage(getIntl("removedFromConference"));
        break;
      case NotificationType.CONFERENCE_END:
        snackbarMessage = intl.formatMessage(getIntl("conferenceHasEnded"));
        break;
      case NotificationType.GLOBAL_THEME_UPDATED:
        if (nLevel === NotificationLevel.SUCCESS) {
          snackbarMessage = intl.formatMessage(
            getIntl("globalThemeUpdateSuccess")
          );
        } else if (nLevel === NotificationLevel.ERROR) {
          snackbarMessage = intl.formatMessage(
            getIntl("globalThemeUpdateFailure")
          );
        }
        break;
      case NotificationType.THEME_PROPERTIES_UPDATED:
        if (nLevel === NotificationLevel.SUCCESS) {
          snackbarMessage = intl.formatMessage(
            getIntl("themePropertiesUpdateSuccess")
          );
        } else if (nLevel === NotificationLevel.ERROR) {
          snackbarMessage = intl.formatMessage(
            getIntl("themePropertiesUpdateFailure")
          );
        }
        break;
      case NotificationType.PERSISTENT_SPACE_THEME_UPDATED:
        if (nLevel === NotificationLevel.SUCCESS) {
          snackbarMessage = intl.formatMessage(
            getIntl("persistentSpaceThemeUpdateSuccess")
          );
        } else if (nLevel === NotificationLevel.ERROR) {
          snackbarMessage = intl.formatMessage(
            getIntl("persistentSpaceThemeUpdateFailure")
          );
        }
        break;
      case NotificationType.PERSISTENT_SPACE_SETTINGS_UPDATED:
        if (nLevel === NotificationLevel.SUCCESS) {
          snackbarMessage = intl.formatMessage(
            getIntl("persistentSpaceSettingsUpdateSuccess")
          );
        } else if (nLevel === NotificationLevel.ERROR) {
          snackbarMessage = intl.formatMessage(
            getIntl("persistentSpaceSettingsUpdateFailure")
          );
        }
        break;
      case NotificationType.THEME_ADDED:
        if (nLevel === NotificationLevel.SUCCESS) {
          snackbarMessage = intl.formatMessage(getIntl("themeAddSuccess"));
        } else if (nLevel === NotificationLevel.ERROR) {
          snackbarMessage = intl.formatMessage(getIntl("themeAddFailure"));
        }
        break;
      case NotificationType.THEME_NAME_EMPTY:
        snackbarMessage = intl.formatMessage(getIntl("themeNameEmpty"));
        break;
      case NotificationType.THEME_DUPLICATE:
        snackbarMessage = intl.formatMessage(getIntl("themeDuplicate"));
        break;
      case NotificationType.FILE_UPLOADED:
        if (nLevel === NotificationLevel.SUCCESS) {
          snackbarMessage = intl.formatMessage(getIntl("fileUploadSuccess"));
        } else if (nLevel === NotificationLevel.ERROR) {
          snackbarMessage = intl.formatMessage(getIntl("fileUploadFailure"));
        }
        break;
      case NotificationType.PARTY_IN_WAITING_ROOM:
        if (nLevel === NotificationLevel.INFO) {
          snackbarMessage = intl.formatMessage(
            getIntl("participantsInWaitingRoom")
          );
        }
        break;
      case NotificationType.AUDIO_INPUT_DEVICE:
        if (nLevel === NotificationLevel.WARNING) {
          snackbarMessage = intl.formatMessage(getIntl("noAudioInputDevice"));
        }
        break;
      case NotificationType.AUDIO_INPUT_DEVICE_PERMISSION:
        if (nLevel === NotificationLevel.WARNING) {
          snackbarMessage = intl.formatMessage(
            getIntl("audioInputDevicePermissionNotGranted")
          );
        }
        break;
      case NotificationType.REMOTE_CONTROL_ENABLED:
        if (nLevel === NotificationLevel.SUCCESS) {
          snackbarMessage = intl.formatMessage(getIntl("enableRemoteControl"));
        }
        break;
      case NotificationType.REMOTE_CONTROL_DISABLED:
        if (nLevel === NotificationLevel.WARNING) {
          snackbarMessage = intl.formatMessage(getIntl("disableRemoteControl"));
        }
        break;
      case NotificationType.CONNECTION_ERROR:
        snackbarMessage = intl.formatMessage(getIntl("connectionError"));
        break;
      default:
    }

    return (
      <span className={classes.messageSpan}>
        <MessageVariantIcon className={classes.messageIconVariant} />
        <span>
          <Typography color="inherit" variant="body2">
            {snackbarMessage}
          </Typography>
        </span>
      </span>
    );
  };

  render() {
    const { classes, notification, variant, className } = this.props;

    const snackbarMessage = this.getSnackbarMessageControls(
      notification.notificationType,
      notification.notificationLevel
    );

    return (
      <Snackbar
        className={classes.root}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        open={notification.windowStatus === NotificationWindowState.OPEN}
        autoHideDuration={
          this.props.autoHideDuration ? this.props.autoHideDuration : 10000
        }
        onClose={
          notification.notificationLevel !== NotificationLevel.ERROR &&
          notification.notificationLevel !== NotificationLevel.WARNING &&
          this.props.hideNotificationWindow
        }
      >
        <SnackbarContent
          className={classNames(classes[variant], className)}
          message={snackbarMessage}
          action={this.getCloseButtonControls()}
        />
      </Snackbar>
    );
  }
}

const mapStateToProps = ({ notification, users, session }) => ({
  notification,
  users,
  session
});

const mapDispatchToProps = dispatch => ({
  hideNotificationWindow: () => dispatch(hideNotificationWindow())
});

SnackbarNotification.propTypes = {
  variant: PropTypes.oneOf(["success", "error", "info", "warning"]).isRequired
};

export default withTheme(
  withStyles(styles)(
    injectIntl(
      connect(mapStateToProps, mapDispatchToProps)(SnackbarNotification)
    )
  )
);
