import {
  NOTIFICATION_WINDW_SHOW,
  NOTIFICATION_WINDOW_HIDE,
  SET_NOTIFICATION_LEVEL,
  SET_NOTIFICATION_TYPE,
  SET_NOTIFICATION_MAP
} from "./actions";
import { NotificationWindowState } from "./";

export default function notificationWindowReducer(
  state = {
    windowStatus: NotificationWindowState.CLOSE,
    notificationLevel: 0,
    map: {}
  },
  action
) {
  let newState = {};
  switch (action.type) {
    case NOTIFICATION_WINDW_SHOW:
      newState = {
        ...state,
        windowStatus: NotificationWindowState.OPEN
      };
      return newState;
    case NOTIFICATION_WINDOW_HIDE:
      newState = {
        ...state,
        windowStatus: NotificationWindowState.CLOSE
      };
      return newState;
    case SET_NOTIFICATION_LEVEL:
      newState = {
        ...state,
        notificationLevel: action.payload
      };
      return newState;
    case SET_NOTIFICATION_TYPE:
      newState = {
        ...state,
        notificationType: action.payload
      };
      return newState;
    case SET_NOTIFICATION_MAP:
      newState = {
        ...state,
        map: { ...action.payload }
      };
      return newState;
    default:
      return state;
  }
}
