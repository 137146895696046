import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { InternalDrawerState } from "../layouts/internalDrawerStates";
import { ParticipantListResponsive } from "../ParticipantList";
import { ChatResponsive } from "../Chat";
import {
  SettingsMenu,
  SettingsMenuResponsive,
  ConferencePreferencesMenu,
  ConferencePreferences
} from "../menus";
import { Room, RoomResponsive } from "../mediasoup/components";
import PoweredByCpx from "../Icons/PoweredByCpx";
import {
  InvitePopperWindow,
  InviteByPhoneWindow,
  InviteParticipantWindowState,
  InviteByPhoneWindowState
} from "../invite";
import { QAWindowState, GuestQAWindow, HostQAWindow } from "../QA";
import { SnackbarNotification, NotificationWindowState } from "../notification";
import { NotificationLevel } from "../notification";
import ParticipantTalkingIndicator from "../statusIndicators/ParticipantTalkingIndicator";
import {
  isHost,
  isHoldStateApplicable,
  isVideoEnabled,
  isCM
} from "../../utils";
import { selectPeers } from "../../selectors";
import CTXDrawer from "../reusable/CTXDrawer";
import { Transcript, transcriptPopoutWindowName } from "../transcript";
import { showTranscriptPopout } from "../../actions/popoutWindowsActions";
import Me from "../mediasoup/components/Me";
import classnames from "classnames";

const styles = theme => ({
  root: {
    backgroundColor: theme.colors.primaryBackgroundColor,
    display: "flex",
    justifyContent: "flex-start",
    gridColumn: "2",
    gridRow: "2",
    msGridColumn: "2",
    msGridRow: "2",
    flexGrow: 1
  },
  mainContent: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "center",
    overflow: "hidden"
  },
  content: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage:
      "url('" +
      process.env.PUBLIC_URL +
      "/" +
      theme.imagesLocation +
      "/" +
      window.CtxThemeConfigurations.backgroundImage +
      "')",
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  fixedPositionContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    top: "50%",
    left: "50%"
  },
  internalDrawer: {
    // The minWidth is needed in order for the size of
    // the video peers to be calculated correctly.
    minWidth: "375px",
    width: "375px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.colors.popoverBackgroundColor
  },
  hidden: {
    display: "none"
  },
  compunetix: {
    position: "fixed",
    bottom: "2em",
    right: "6em",
    opacity: 100
  },
  compunetixDimension: {
    height: "20px"
  },
  overflowHidden: {
    overflow: "hidden"
  },
  talkerIndicator: {
    position: "fixed",
    top: "64px"
  },
  talkerIndicatorNoHeader: {
    position: "fixed",
    top: "0px"
  },
  fullScreenMainContent: {
    gridArea: "1/1/3/4"
  },
  videoContainer: {
    alignSelf: "center",
    width: "320px",
    height: "180px",
    borderColor: "transparent",
    borderStyle: "solid"
  },
  activeSpeaker: {
    borderColor: theme.colors.connectIconColor
  }
});

class MainContent extends Component {
  getParticipantTalkerIndicator = () => {
    const { classes, session } = this.props;
    const { talkerIndicatorOption, isHeaderBarsVisible } = session;
    const lastPartyId = this.getTalkerPartyId();
    const partyName = this.getPartyNameById(lastPartyId);
    const enableTalker = partyName && talkerIndicatorOption === 1;

    return (
      enableTalker && (
        <div
          className={
            isHeaderBarsVisible
              ? classes.talkerIndicator
              : classes.talkerIndicatorNoHeader
          }
        >
          <ParticipantTalkingIndicator partyName={partyName} />
        </div>
      )
    );
  };

  getTalkerPartyId = () => {
    const { session } = this.props;
    const { talkerPartySet } = session;

    if (talkerPartySet) {
      if (talkerPartySet.size > 0) {
        let lastPartyId;
        for (lastPartyId of talkerPartySet);
        return lastPartyId;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  };

  getPartyNameById = partyId => {
    const { participants } = this.props;
    const { merged } = participants;

    if (merged.length === 0) {
      return undefined;
    } else {
      const resPartyObj = merged.find(function (currentPartyObj) {
        return currentPartyObj.partyID === partyId;
      });

      if (resPartyObj) {
        return resPartyObj.name;
      } else {
        return undefined;
      }
    }
  };

  getNotificationVariant = notificationLevel => {
    switch (notificationLevel) {
      case NotificationLevel.SUCCESS:
        return "success";
      case NotificationLevel.ERROR:
        return "error";
      case NotificationLevel.INFO:
        return "info";
      case NotificationLevel.WARNING:
        return "warning";
      default:
        return "";
    }
  };

  showTranscriptPopout = () => {
    this.props.showTranscriptPopout(transcriptPopoutWindowName);
  };

  render() {
    const {
      classes,
      component,
      session,
      qa,
      notification,
      inviteParticipant,
      conference,
      portal,
      participants,
      peers,
      me
    } = this.props;

    let { internalDrawer } = this.props;
    const userId = session.userId;
    const host = isHost(session);
    const holdState = isHoldStateApplicable(conference, session);

    let internalDrawerClassNames = [classes.internalDrawer];
    let internalDrawerContent;

    let amActiveSpeaker = false;

    if (session.talkerPartySet.size > 0) {
      participants.merged.forEach(mergedUser => {
        if (
          mergedUser != null &&
          mergedUser.talking &&
          mergedUser.id === me.id
        ) {
          amActiveSpeaker = true;
        }
      });
    }

    if (
      !session.guid ||
      !session.isLoggedIn ||
      !session.vetted ||
      (isCM() && !session.cmLoggedIn) ||
      (internalDrawer === InternalDrawerState.PARTICIPANT_LIST &&
        !host &&
        session.disableParticipantListForGuests)
    ) {
      internalDrawer = InternalDrawerState.HIDDEN;
    }

    switch (internalDrawer) {
      case InternalDrawerState.PARTICIPANT_LIST:
        internalDrawerContent = <ParticipantListResponsive isDrawer />;
        break;
      case InternalDrawerState.CHAT:
        internalDrawerContent = <ChatResponsive isDrawer />;
        break;
      case InternalDrawerState.VIDEO:
        internalDrawerContent = (
          <RoomResponsive
            isDrawer
            windowWidth={this.props.window.width}
            windowHeight={this.props.window.height}
          />
        );
        break;
      case InternalDrawerState.SETTINGS:
        internalDrawerContent = <SettingsMenuResponsive isDrawer />;
        break;
      case InternalDrawerState.CONFERENCE_PREFERENCES:
        internalDrawerContent = (
          <CTXDrawer>
            <ConferencePreferences />
          </CTXDrawer>
        );
        break;
      case InternalDrawerState.TRANSCRIPT:
        internalDrawerContent = (
          <CTXDrawer popout onPopoutClick={this.showTranscriptPopout}>
            <Transcript />
          </CTXDrawer>
        );
        break;
      case InternalDrawerState.HIDDEN:
      default:
        internalDrawerClassNames.push(classes.hidden);
        break;
    }

    const settingsMenu = session.settingsMenuState ? (
      <SettingsMenu />
    ) : undefined;

    const conferencePreferencesMenu = session.conferencePreferencesMenuState ? (
      <ConferencePreferencesMenu />
    ) : undefined;

    const notificationWindow =
      notification.windowStatus === NotificationWindowState.OPEN &&
      !portal.theme.isThemeEditorModalOpen ? (
        <SnackbarNotification
          variant={this.getNotificationVariant(notification.notificationLevel)}
        />
      ) : undefined;

    const qaWindow =
      qa.QAWindowStatus === QAWindowState.OPEN ? (
        host ? (
          <HostQAWindow />
        ) : (
          <GuestQAWindow />
        )
      ) : undefined;

    const inviteWindow =
      inviteParticipant.inviteParticipantWindowStatus ===
      InviteParticipantWindowState.OPEN ? (
        <InvitePopperWindow />
      ) : undefined;

    const inviteByPhoneWindow =
      inviteParticipant.inviteByPhoneWindowStatus ===
      InviteByPhoneWindowState.OPEN ? (
        <InviteByPhoneWindow />
      ) : undefined;

    const windows = (
      <Fragment>
        {qaWindow}
        {settingsMenu}
        {conferencePreferencesMenu}
        {inviteWindow}
        {inviteByPhoneWindow}
        {notificationWindow}
      </Fragment>
    );

    return (
      <div
        className={classNames(
          classes.root,
          userId && classes.overflowHidden,
          session.fullScreenMode && classes.fullScreenMainContent
        )}
      >
        <div
          className={classNames(internalDrawerClassNames)}
          ref={r => (this.wrappedRef = r)}
        >
          {internalDrawerContent}
          {isVideoEnabled(session.videoConfig) &&
            session.drawerSelfViewEnabled && (
              <div
                className={classnames(classes.videoContainer, {
                  [classes.activeSpeaker]: amActiveSpeaker
                })}
              >
                <Me isDrawer={true} />
              </div>
            )}
        </div>
        <div className={classes.mainContent}>
          {isVideoEnabled(session.videoConfig) &&
          !holdState &&
          session.vetted &&
          session.isMainRoom &&
          session.guid &&
          (!window.CtxAppConfigurations.experimentalModeEnabled ||
            peers.length > 0) &&
          (!isCM() || session.cmLoggedIn) ? (
            <Room
              windowWidth={this.props.window.width}
              windowHeight={this.props.window.height}
            />
          ) : (
            <div className={classes.content} style={this.props.style}>
              {component}
            </div>
          )}
          <div className={classes.fixedPositionContent}>
            {this.getParticipantTalkerIndicator()}
            {windows}
          </div>
        </div>
        <div className={classes.compunetix}>
          <PoweredByCpx classes={{ root: classes.compunetixDimension }} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  let { internalDrawer } = state;
  const {
    session,
    qa,
    participants,
    notification,
    inviteParticipant,
    conference,
    window,
    portal,
    me
  } = state;

  if (
    session.recorder &&
    internalDrawer === InternalDrawerState.PARTICIPANT_LIST
  ) {
    internalDrawer = InternalDrawerState.HIDDEN;
  }

  return {
    internalDrawer,
    session,
    qa,
    participants,
    notification,
    inviteParticipant,
    conference,
    window,
    portal,
    me,
    peers: selectPeers(state)
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, {
    showTranscriptPopout
  })(MainContent)
);
