import React, { Component, Fragment } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputBase from "@material-ui/core/InputBase";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { withTheme } from "@material-ui/core/styles";
import { setAudioInput, setAudioOutput, hideSettingsMenu } from "../../actions";
import classNames from "classnames";
import SvgIcon from "../Icons/SvgIcon";
import LocalizedText from "../reusable/LocalizedText";
import { getIntl, getDevices } from "../../utils";
import Logger from "../../Logger";
import CTXSlider from "../reusable/CTXSlider";

const logger = new Logger("DeviceSettings");

const styles = theme => ({
  mainContent: {
    display: "flex",
    flexDirection: "column",
    width: "400px",
    paddingBottom: "10px"
  },
  selectContainer: {
    width: "100%",
    height: "5%",
    borderRadius: "10px",
    color: theme.colors.primaryTextColor,
    paddingRight: "30px"
  },
  selectIcon: {
    top: "calc(29% - 12px)",
    color: theme.colors.primaryTextColor,
    fontSize: "2.25em"
  },
  inputBox: {
    borderBottom: `2px solid ${theme.colors.secondaryMainColor}`,
    color: theme.colors.primaryTextColor,
    textAlign: "left"
  },
  menuItem: {
    color: theme.colors.primaryTextColor,
    paddingRight: "20px",
    width: "100%"
  },
  menuItemGroup: {
    border: `3px solid ${theme.colors.secondaryMainColor}`,
    borderRadius: "5px",
    paddingLeft: "0px",
    paddingRight: "0px"
  },
  settingsIcon: {
    fontSize: "1.2em",
    padding: "8px"
  },
  settingsSvgIcon: {
    width: "1.75em",
    padding: "3px"
  },
  hiddenSvgIcon: {
    width: "1.75em",
    padding: "3px",
    visibility: "hidden"
  },
  flexRowBox: {
    display: "flex",
    flexDirection: "row",
    paddingTop: "15px",
    alignItems: "center"
  },
  flexItemSmall: {
    flexGrow: 1
  },
  flexItemLarge: {
    flexGrow: 4,
    width: "95%"
  },
  clickableText: {
    color: theme.colors.connectIconColor,
    paddingLeft: "12px"
  },
  canvas: {
    margin: "15px 18px 0 28px",
    height: "5px"
  },
  primaryTextColor: {
    color: theme.colors.primaryTextColor
  },
  secondaryTextColor: {
    color: theme.colors.secondaryTextColor
  }
});

class DeviceSettings extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.audioInputDevices = new Map();
    this.audioElement = document.getElementById("remoteMedia");
    this.webRTCAudioElement = document.getElementById("webRTCMedia");

    document.getElementById("remoteMedia").addEventListener("play", () => {
      if (this._isMounted) {
        this.setState({
          speakerTestInProgress: true
        });
      }
    });

    document.getElementById("remoteMedia").addEventListener("ended", () => {
      if (this._isMounted) {
        this.setState({
          speakerTestInProgress: false
        });
      }
    });

    document.getElementById("remoteMedia").addEventListener("pause", () => {
      if (this._isMounted && this.state.speakerTestInProgress) {
        this.setState({
          speakerTestInProgress: false
        });
      }
    });
  }

  state = {
    audioInputDevicesOpen: false,
    audioInputDevices: [],
    audioOutputDevicesOpen: false,
    audioOutputDevices: [],
    defaultAudioOutputVolume: 0,
    audioTestInProgress: false,
    speakerTestInProgress: false
  };

  async componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.getDevices();
      this.setState({
        defaultAudioOutputVolume: this.audioElement.volume * 100
      });

      try {
        let stream = await navigator.mediaDevices.getUserMedia({
          audio: {
            deviceId: this.props.session.audioInput
          }
        });
        stream.getTracks().forEach(track => {
          track.stop();
        });
      } catch (error) {
        this.errorCallbackOnUserMedia(error);
      }

      if (!this._isMounted) {
        return;
      }

      await this.getDevices();
      this.startMicrophoneTest();

      navigator.mediaDevices.addEventListener("devicechange", this.getDevices);
    }
  }

  componentWillUnmount() {
    if (this.state.audioTestInProgress) {
      this.stopMicrophoneTest();
    }

    if (this._isMounted) {
      this.audioElement.pause();
    }
    this._isMounted = false;

    navigator.mediaDevices.removeEventListener("devicechange", this.getDevices);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.session.audioInput !== this.props.session.audioInput &&
      this.state.audioTestInProgress
    ) {
      this.updateVisualizer();
    }
  }

  getDevices = async () => {
    try {
      const state = await getDevices(this.props);
      this.setState(state);
    } catch (error) {
      this.errorCallbackOnListDevices(error);
    }
  };

  errorCallbackOnListDevices = error => {
    logger.error("errorCallbackOnListDevices: %o", error);
  };

  setAudioInput = value => {
    localStorage.setItem("audioInput", value);
    this.props.setAudioInput(value);
  };

  setAudioOutput = value => {
    localStorage.setItem("audioOutput", value);
    this.props.setAudioOutput(value);
  };

  listAudioInputDevices = classes => {
    let audioInputItems = [];
    let hintDisplayed;
    if (!this.state.inputError) {
      for (let device of this.state.audioInputDevices) {
        let deviceLabel = device.label;
        let deviceHint = "";
        if (device.deviceId === "default") {
          deviceLabel = "Default";
          deviceHint = device.label.substring(7);
        } else if (device.deviceId === "communications") {
          deviceLabel = "Communications";
          deviceHint = device.label.substring(14);
        }
        audioInputItems.push(
          <MenuItem
            key={device.deviceId}
            value={device.deviceId}
            classes={{ root: classes.menuItem }}
          >
            <span>
              <span className={classes.primaryTextColor}>{deviceLabel}</span>
              <span className={classes.secondaryTextColor}> {deviceHint}</span>
            </span>
          </MenuItem>
        );
      }
      let audioInput = this.props.session.audioInput;
      if (audioInput === "default" || audioInput === "communications") {
        hintDisplayed = true;
      }
    } else {
      let errorText;
      if (this.state.inputError === "noDevices") {
        errorText = this.props.intl.formatMessage(getIntl("noInputDevices"));
      } else {
        errorText = this.props.intl.formatMessage(
          getIntl("permissionNotGranted")
        );
      }
      audioInputItems.push(
        <MenuItem
          key="inputError"
          value="inputError"
          classes={{ root: classes.menuItem }}
        >
          <span className={classes.primaryTextColor}>{errorText}</span>
        </MenuItem>
      );
    }

    return (
      <div className={classes.flexRowBox}>
        <div className={classes.flexItemSmall}>
          <SvgIcon
            iconName="microphone"
            color="active"
            className={classes.settingsSvgIcon}
          />
        </div>
        <div className={classes.flexItemLarge}>
          <Select
            disabled={this.state.inputError != null}
            autoWidth={false}
            value={
              audioInputItems.length > 0
                ? this.state.inputError
                  ? "inputError"
                  : this.props.session.audioInput
                : ""
            }
            onChange={event => this.setAudioInput(event.target.value)}
            classes={{
              icon: classNames({
                [classes.selectIcon]: this.state.inputError == null
              }),
              root: classes.selectContainer,
              select: classNames(classes.menuItem, {
                [classes.secondaryTextColor]: hintDisplayed
              })
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              getContentAnchorEl: null,
              classes: { paper: classes.menuItemGroup }
            }}
            input={
              <InputBase
                id="selectedAudioInput"
                fullWidth={false}
                className={classNames(classes.inputBox, classes.flexItemLarge)}
              />
            }
          >
            {audioInputItems}
          </Select>
        </div>
      </div>
    );
  };

  listAudioOutputDevices = classes => {
    let audioOutputItems = [];
    let hintDisplayed;
    if (!this.state.outputError) {
      for (let device of this.state.audioOutputDevices) {
        let deviceLabel = device.label;
        let deviceHint = "";
        if (device.deviceId === "default") {
          deviceLabel = "Default";
          deviceHint = device.label.substring(7);
        } else if (device.deviceId === "communications") {
          deviceLabel = "Communications";
          deviceHint = device.label.substring(14);
        }
        audioOutputItems.push(
          <MenuItem
            key={device.deviceId}
            value={device.deviceId}
            classes={{ root: classes.menuItem }}
          >
            <span>
              <span className={classes.primaryTextColor}>{deviceLabel}</span>
              <span className={classes.secondaryTextColor}> {deviceHint}</span>
            </span>
          </MenuItem>
        );
      }
      let audioOutput = this.props.session.audioOutput;
      if (audioOutput === "default" || audioOutput === "communications") {
        hintDisplayed = true;
      }
    } else {
      let errorText;
      if (this.state.outputError === "noDevices") {
        errorText = this.props.intl.formatMessage(getIntl("noOutputDevices"));
      } else {
        errorText = this.props.intl.formatMessage(getIntl("default"));
      }
      audioOutputItems.push(
        <MenuItem
          key="outputError"
          value="outputError"
          classes={{ root: classes.menuItem }}
        >
          <span className={classes.primaryTextColor}>{errorText}</span>
        </MenuItem>
      );
    }

    return (
      <div className={classes.flexRowBox}>
        <div className={classes.flexItemSmall}>
          <SvgIcon
            iconName="speaker"
            color="active"
            className={classes.settingsSvgIcon}
          />
        </div>
        <div className={classes.flexItemLarge}>
          <Select
            disabled={this.state.outputError != null}
            autoWidth={false}
            value={
              audioOutputItems.length > 0
                ? this.state.outputError
                  ? "outputError"
                  : this.props.session.audioOutput
                : ""
            }
            onChange={event => this.setAudioOutput(event.target.value)}
            classes={{
              icon: classNames({
                [classes.selectIcon]: this.state.outputError == null
              }),
              root: classes.selectContainer,
              select: classNames(classes.menuItem, {
                [classes.secondaryTextColor]: hintDisplayed
              })
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              getContentAnchorEl: null,
              classes: { paper: classes.menuItemGroup }
            }}
            input={
              <InputBase
                id="selectedAudioOutput"
                fullWidth={false}
                className={classNames(classes.inputBox, classes.flexItemLarge)}
              />
            }
          >
            {audioOutputItems}
          </Select>
        </div>
      </div>
    );
  };

  getSpeakerVolumeAndTestControls = (
    classes,
    defaultAudioOutputVolume,
    speakerTestInProgress
  ) => {
    return (
      <div className={classes.flexRowBox}>
        <div className={classes.flexItemSmall}>
          <SvgIcon
            iconName="speaker"
            color=""
            className={classes.hiddenSvgIcon}
          />
        </div>
        <div className={classes.flexItemLarge}>
          <CTXSlider
            value={defaultAudioOutputVolume}
            onChange={this.handleSpeakerVolumeChange}
            onIncrease={() =>
              this.increaseSpeakerVolume(defaultAudioOutputVolume)
            }
            onDecrease={() =>
              this.decreaseSpeakerVolume(defaultAudioOutputVolume)
            }
          />
        </div>
        <div className={classes.flexItemSmall}>
          {!speakerTestInProgress ? (
            <Button onClick={() => this.testSpeakers()}>
              <LocalizedText value="test" className={classes.clickableText} />
            </Button>
          ) : (
            <Button onClick={() => this.endSpeakersTest()}>
              <LocalizedText value="stop" className={classes.clickableText} />
            </Button>
          )}
        </div>
      </div>
    );
  };

  increaseSpeakerVolume = defaultAudioOutputVolume => {
    if (defaultAudioOutputVolume < 100) {
      let value = defaultAudioOutputVolume + 10;
      if (value > 100) {
        value = 100;
      }
      this.audioElement.volume = (value / 100).toFixed(2);
      this.webRTCAudioElement.volume = this.audioElement.volume;
      this.setState({ defaultAudioOutputVolume: value });
    }
  };

  decreaseSpeakerVolume = defaultAudioOutputVolume => {
    if (defaultAudioOutputVolume > 0) {
      let value = defaultAudioOutputVolume - 10;
      if (value < 0) {
        value = 0;
      }
      this.audioElement.volume = (value / 100).toFixed(2);
      this.webRTCAudioElement.volume = this.audioElement.volume;
      this.setState({ defaultAudioOutputVolume: value });
    }
  };

  handleSpeakerVolumeChange = (event, value) => {
    this.audioElement.volume = (value / 100).toFixed(2);
    this.webRTCAudioElement.volume = this.audioElement.volume;
    this.setState({ defaultAudioOutputVolume: value });
  };

  startMicrophoneTest = async () => {
    if (navigator.mediaDevices.getUserMedia) {
      logger.debug("getUserMedia supported.");
      this.setState({ audioTestInProgress: true });
      try {
        window.stream = await navigator.mediaDevices.getUserMedia({
          audio: {
            deviceId: this.props.session.audioInput
          }
        });
        this.updateVisualizer();
      } catch (error) {
        this.errorCallbackOnUserMedia(error);
      }
    } else {
      logger.error("getUserMedia not supported on your browser!");
    }
  };

  updateVisualizer = () => {
    logger.debug("stream in updateVisualizer: %o", window.stream);
    if (window.stream) {
      this.visualize("OFF");
      this.audioCtx = new (window.AudioContext || window.webkitAudioContext)();
      var analyser = this.audioCtx.createAnalyser();
      var source = this.audioCtx.createMediaStreamSource(window.stream);
      source.connect(analyser);
      this.visualize("ON", analyser);
    }
  };

  errorCallbackOnUserMedia = error => {
    logger.error("errorCallbackOnUserMedia: %o", error);
  };

  visualize = (visualSetting, analyser) => {
    const { theme } = this.props;
    let canvas = document.getElementById("visualizer");
    let canvasCtx = canvas.getContext("2d");

    let WIDTH = canvas.width;
    let HEIGHT = canvas.height;

    if (visualSetting === "ON") {
      analyser.fftSize = 512;
      let dataArray = new Uint8Array(analyser.fftSize);
      let averaging = 0.95;
      this.inputLevel = 0;
      var drawVisual;
      canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);

      var draw = () => {
        drawVisual = requestAnimationFrame(draw);
        analyser.getByteTimeDomainData(dataArray);

        canvasCtx.fillStyle = theme.colors.primaryBackgroundColor;
        canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);

        // Calculate the root mean square of the samples
        let sum = 0;
        for (let i = 0; i < dataArray.length; i++) {
          let val;
          if (dataArray[i] === 127 || dataArray[i] === 128) {
            val = 0;
          } else {
            val = (dataArray[i] - 127.5) / 127.5;
          }
          sum += val * val;
        }
        let rms = Math.sqrt(sum / dataArray.length);

        let decibel = this.convertToDB(rms);
        this.inputLevel = Math.max(decibel, this.inputLevel * averaging);

        canvasCtx.fillStyle = theme.colors.connectIconColor;
        canvasCtx.fillRect(0, 0, WIDTH * this.inputLevel, HEIGHT);
      };

      draw();
    } else if (visualSetting === "OFF") {
      canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);
      window.cancelAnimationFrame(drawVisual);
      drawVisual = null;
      draw = null;
      this.audioCtx = null;
    }
  };

  convertToDB = value => {
    value = (20 * Math.log10(value) + 60) / 60;
    if (value < 0) {
      value = 0;
    }
    return value;
  };

  stopMicrophoneTest = () => {
    this.visualize("OFF");
    this.setState({ audioTestInProgress: false });
    this.stopTestOnUserMedia();
  };

  stopTestOnUserMedia = () => {
    logger.debug("stream in stopTestOnUserMedia: %o", window.stream);
    if (window.stream != null) {
      logger.debug("Found stream as active, hence stopping the tracks");
      window.stream.getTracks().forEach(track => track.stop());
      window.stream = null;
    }
  };

  testSpeakers = () => {
    this.audioElement.play();
  };

  endSpeakersTest = () => {
    this.audioElement.pause();
  };

  render() {
    const { classes } = this.props;
    const { defaultAudioOutputVolume, speakerTestInProgress } = this.state;
    return (
      <Fragment>
        <div className={classes.mainContent}>
          {this.listAudioInputDevices(classes)}
          <canvas id="visualizer" className={classes.canvas} />
          {this.listAudioOutputDevices(classes)}
          {this.getSpeakerVolumeAndTestControls(
            classes,
            defaultAudioOutputVolume,
            speakerTestInProgress
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ session }) => ({
  session
});

const mapDispatchToProps = dispatch => ({
  hideSettingsMenu: () => dispatch(hideSettingsMenu()),
  setAudioInput: audioInput => dispatch(setAudioInput(audioInput)),
  setAudioOutput: audioOutput => dispatch(setAudioOutput(audioOutput))
});

export default withTheme(
  withStyles(styles)(
    injectIntl(connect(mapStateToProps, mapDispatchToProps)(DeviceSettings))
  )
);
