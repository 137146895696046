import React, { Component } from "react";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import classNames from "classnames";
import { getIntl } from "../../utils";
import {
  Card,
  CardHeader,
  CardContent,
  Checkbox,
  TextField,
  InputBase,
  MenuItem
} from "@material-ui/core";
import TextButton from "../reusable/TextButton";
import LocalizedText from "../reusable/LocalizedText";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { updatePersistentSpaceSettingsByUserID } from "./actions";
import CTXSelect from "../reusable/CTXSelect";

const styles = theme => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column"
  },
  rootFullHeight: {
    height: "100%"
  },
  hrDivPrimary: {
    borderTop: `3px solid ${theme.colors.primaryTextColor}`
  },
  rootBackgroundColor: {
    backgroundColor: theme.colors.primaryBackgroundColor
  },
  cardHeader: {
    padding: "16px 40px 5px 40px"
  },
  cardHeaderTitle: {
    height: "35px"
  },
  cardContent: {
    padding: "0px 40px 5px 40px",
    flexGrow: 1
  },
  scrollBar: {
    "&::-webkit-scrollbar": {
      width: "8px",
      height: "8px"
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.colors.secondaryMainColor,
      borderRadius: "4px",
      border: "none"
    },
    "&::-webkit-scrollbar-track-piece": {
      background: theme.colors.sideBarBackgroundColor,
      borderRadius: "4px"
    },
    "&::-webkit-scrollbar-corner": {
      background: theme.colors.sideBarBackgroundColor
    },
    scrollbarColor: `${theme.colors.secondaryMainColor} ${theme.colors.popoverBackgroundColor}`,
    scrollbarWidth: "thin"
  },
  settingsContainer: {
    padding: "10px 50px 0px 30px"
  },
  settingsRow: {
    display: "flex",
    alignItems: "center",
    height: "48px",
    gap: "5px"
  },
  largeHeight: {
    height: "80px"
  },
  actionButton: {
    paddingRight: "10px"
  },
  checkBox: {
    color: theme.colors.primaryMainColor,
    paddingLeft: "14px"
  },
  checkboxOutlineBlankIcon: {
    width: "18px",
    height: "18px"
  },
  checkboxIcon: {
    backgroundColor: theme.colors.primaryMainTextColor,
    borderRadius: "4px",
    width: "18px",
    height: "18px"
  },
  textField: {
    width: "400px"
  },
  label: {
    marginLeft: "47px",
    width: "200px"
  }
});

class PersistentSpaceSettingsCard extends Component {
  state = {
    userID: null,
    persistentSpaceSettings: {
      persistentSpaceName: "",
      callMeConfig: false,
      webRTCCallConfig: false,
      videoConfig: false,
      disableParticipantListForGuests: false,
      conferenceRoomControlConfig: false,
      vettingConfig: false,
      iframeSource: "",
      waitingRoomMessage: "",
      redirectURL: "",
      transferGroup: "",
      guestsJoinedInLockedMute: false,
      maxDisplayedUsers: "all"
    }
  };

  constructor(props) {
    super(props);

    const { persistentSpace } = this.props;
    this.state = {
      userID: this.props.userID,
      persistentSpaceSettings: {
        persistentSpaceName: persistentSpace.persistentSpaceName,
        callMeConfig: persistentSpace.callMeConfig,
        webRTCCallConfig: persistentSpace.webRTCCallConfig,
        videoConfig: persistentSpace.videoConfig,
        disableParticipantListForGuests:
          persistentSpace.disableParticipantListForGuests,
        conferenceRoomControlConfig:
          persistentSpace.conferenceRoomControlConfig,
        vettingConfig: persistentSpace.vettingConfig,
        iframeSource: persistentSpace.iframeSource,
        waitingRoomMessage: persistentSpace.waitingRoomMessage,
        redirectURL: persistentSpace.redirectURL,
        transferGroup: persistentSpace.transferGroup,
        guestsJoinedInLockedMute: persistentSpace.guestsJoinedInLockedMute,
        maxDisplayedUsers:
          persistentSpace.maxDisplayedUsers == null
            ? "all"
            : persistentSpace.maxDisplayedUsers
      }
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.userID != null) {
      if (
        prevProps.userID !== this.props.userID ||
        prevProps.persistentSpace.persistentSpaceName !==
          this.props.persistentSpace.persistentSpaceName
      ) {
        const { persistentSpace } = this.props;

        this.setState({
          userID: this.props.userID,
          persistentSpaceSettings: {
            persistentSpaceName: persistentSpace.persistentSpaceName,
            callMeConfig: persistentSpace.callMeConfig,
            webRTCCallConfig: persistentSpace.webRTCCallConfig,
            videoConfig: persistentSpace.videoConfig,
            disableParticipantListForGuests:
              persistentSpace.disableParticipantListForGuests,
            conferenceRoomControl: persistentSpace.conferenceRoomControlConfig,
            vettingConfig: persistentSpace.vettingConfig,
            iframeSource: persistentSpace.iframeSource,
            waitingRoomMessage: persistentSpace.waitingRoomMessage,
            redirectURL: persistentSpace.redirectURL,
            transferGroup: persistentSpace.transferGroup,
            guestsJoinedInLockedMute: persistentSpace.guestsJoinedInLockedMute,
            maxDisplayedUsers:
              persistentSpace.maxDisplayedUsers == null
                ? "all"
                : persistentSpace.maxDisplayedUsers
          }
        });
      }
    }
  }

  getCardHeaderControl = () => {
    const { classes, intl } = this.props;
    const persistentSpaceName =
      this.state.persistentSpaceSettings.persistentSpaceName;

    return (
      <CardHeader
        title={
          persistentSpaceName
            ? persistentSpaceName
            : intl.formatMessage(getIntl("na"))
        }
        titleTypographyProps={{ color: "inherit", variant: "h5" }}
        classes={{
          root: classes.cardHeader,
          title: classes.cardHeaderTitle,
          action: classes.actionButton
        }}
        action={
          <TextButton
            disabled={this.props.buttonLoader}
            onClick={this.handleSaveButtonOnClick}
          >
            <LocalizedText value="save" />
          </TextButton>
        }
      />
    );
  };

  getCardContentControl = () => {
    const { classes } = this.props;

    return (
      <CardContent
        classes={{
          root: classes.cardContent
        }}
      >
        <div className={classes.hrDivPrimary} />
        {this.getPersistentSpaceSettings()}
      </CardContent>
    );
  };

  getPersistentSpaceSettings = () => {
    const { classes } = this.props;
    let settingsArray = [];

    if (window.CtxAppConfigurations.customer !== "TCAD") {
      if (window.CtxAppConfigurations.audioConnectionControlVisible) {
        settingsArray.push(this.getSettingsRow("callMeConfig"));
        settingsArray.push(this.getSettingsRow("webRTCCallConfig"));
      }
      if (window.CtxAppConfigurations.videoLicenseActive) {
        settingsArray.push(this.getSettingsRow("videoConfig"));
        settingsArray.push(this.getMaxDisplayedUsersRow());
      }
      settingsArray.push(
        this.getSettingsRow("disableParticipantListForGuests")
      );
      settingsArray.push(this.getSettingsRow("conferenceRoomControlConfig"));
      if (window.CtxAppConfigurations.lockAudioModeEnabled) {
        settingsArray.push(this.getSettingsRow("guestsJoinedInLockedMute"));
      }
      settingsArray.push(this.getTextFieldRow("redirectURL", 255, 1, 2));
      if (window.CtxAppConfigurations.partyTransferEnabled) {
        settingsArray.push(this.getTextFieldRow("transferGroup", 255, 1, 2));
      }
    }

    if (window.CtxAppConfigurations.vettingEnabled) {
      settingsArray.push(this.getSettingsRow("vettingConfig"));
      settingsArray.push(this.getTextFieldRow("iframeSource", 255, 1, 2));
      settingsArray.push(this.getTextFieldRow("waitingRoomMessage", 255, 1, 2));
    }

    return <div className={classes.settingsContainer}>{settingsArray}</div>;
  };

  getSettingsRow = settingName => {
    const { classes } = this.props;
    return (
      <div className={classes.settingsRow} key={settingName}>
        <Checkbox
          className={classes.checkBox}
          checked={this.state.persistentSpaceSettings[settingName]}
          onChange={event => this.handleSettingChange(event, settingName)}
          color="primary"
          icon={
            <CheckBoxOutlineBlankIcon
              viewBox="3 3 18 18"
              className={classes.checkboxOutlineBlankIcon}
            />
          }
          checkedIcon={
            <CheckBoxIcon
              viewBox="3 3 18 18"
              className={classes.checkboxIcon}
            />
          }
        />
        <LocalizedText variant="body1" value={settingName} />
      </div>
    );
  };

  getTextFieldRow = (
    settingName,
    textFieldLength,
    textFieldMinRows,
    textFieldMaxRows
  ) => {
    const { classes } = this.props;
    return (
      <div
        className={classNames(classes.settingsRow, classes.largeHeight)}
        key={settingName}
      >
        <LocalizedText
          className={classes.label}
          variant="body1"
          value={settingName}
        />
        <TextField
          className={classes.textField}
          onChange={event => this.handleTextFieldOnChange(event, settingName)}
          value={this.state.persistentSpaceSettings[settingName]}
          type="text"
          autoFocus
          InputProps={{
            classes: {
              input: classNames(classes.scrollBar)
            }
          }}
          inputProps={{
            maxLength: textFieldLength
          }}
          multiline
          minRows={textFieldMinRows}
          maxRows={textFieldMaxRows}
        />
      </div>
    );
  };

  handleTextFieldOnChange = (event, settingName) => {
    let settingValue = event.target.value;

    this.setState(prevState => ({
      ...prevState,
      persistentSpaceSettings: {
        ...prevState.persistentSpaceSettings,
        [settingName]: settingValue
      }
    }));
  };

  handleSettingChange = (event, settingName) => {
    let settingValue = event.target.checked;

    this.setState(prevState => ({
      ...prevState,
      persistentSpaceSettings: {
        ...prevState.persistentSpaceSettings,
        [settingName]: settingValue
      }
    }));
  };

  getMaxDisplayedUsersRow = () => {
    const { classes, intl } = this.props;
    return (
      <div className={classes.settingsRow} key="maxDisplayedUsers">
        <LocalizedText
          className={classes.label}
          variant="body1"
          value="maxDisplayedUsers"
        />
        <CTXSelect
          value={this.state.persistentSpaceSettings.maxDisplayedUsers}
          onChange={this.handleMaxDisplayedUsersChange}
          input={<InputBase />}
        >
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={4}>4</MenuItem>
          <MenuItem value={9}>9</MenuItem>
          <MenuItem value={"all"}>
            {intl.formatMessage(getIntl("all"))}
          </MenuItem>
        </CTXSelect>
      </div>
    );
  };

  handleMaxDisplayedUsersChange = event => {
    this.setState(state => ({
      persistentSpaceSettings: {
        ...state.persistentSpaceSettings,
        maxDisplayedUsers: event.target.value
      }
    }));
  };

  handleSaveButtonOnClick = () => {
    const { session } = this.props;
    const { userID, persistentSpaceSettings } = this.state;

    this.props.updatePersistentSpaceSettingsByUserID(
      session.portalSessionId,
      userID,
      persistentSpaceSettings.persistentSpaceName,
      persistentSpaceSettings.callMeConfig,
      persistentSpaceSettings.webRTCCallConfig,
      persistentSpaceSettings.videoConfig,
      persistentSpaceSettings.disableParticipantListForGuests,
      persistentSpaceSettings.conferenceRoomControlConfig,
      persistentSpaceSettings.vettingConfig,
      persistentSpaceSettings.iframeSource,
      persistentSpaceSettings.waitingRoomMessage,
      persistentSpaceSettings.redirectURL,
      persistentSpaceSettings.transferGroup,
      persistentSpaceSettings.guestsJoinedInLockedMute,
      persistentSpaceSettings.maxDisplayedUsers === "all"
        ? null
        : persistentSpaceSettings.maxDisplayedUsers
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <Card
        className={classNames(
          classes.root,
          this.props.fullHeight ? classes.rootFullHeight : undefined,
          this.props.backgroundColor ? undefined : classes.rootBackgroundColor
        )}
        style={{
          backgroundColor: this.props.backgroundColor
            ? this.props.backgroundColor
            : undefined
        }}
      >
        {this.getCardHeaderControl()}
        {this.getCardContentControl()}
      </Card>
    );
  }
}

const mapStateToProps = ({ session, portal }) => ({ session, portal });

const mapDispatchToProps = dispatch => ({
  updatePersistentSpaceSettingsByUserID: (
    portalSessionId,
    userID,
    name,
    callMeConfig,
    webRTCCallConfig,
    videoConfig,
    disableParticipantListForGuests,
    conferenceRoomControlConfig,
    vettingConfig,
    iframeSource,
    waitingRoomMessage,
    redirectURL,
    transferGroup,
    guestsJoinedInLockedMute,
    maxDisplayedUsers
  ) =>
    dispatch(
      updatePersistentSpaceSettingsByUserID(
        portalSessionId,
        userID,
        name,
        callMeConfig,
        webRTCCallConfig,
        videoConfig,
        disableParticipantListForGuests,
        conferenceRoomControlConfig,
        vettingConfig,
        iframeSource,
        waitingRoomMessage,
        redirectURL,
        transferGroup,
        guestsJoinedInLockedMute,
        maxDisplayedUsers
      )
    )
});

export default withTheme(
  withStyles(styles)(
    injectIntl(
      connect(mapStateToProps, mapDispatchToProps)(PersistentSpaceSettingsCard)
    )
  )
);
