import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withTheme } from "@material-ui/core/styles";
import { hideSettingsMenu, setControlsTabView } from "../../actions";
import CTXBaseWindow from "../reusable/CTXBaseWindow";
import DeviceSettings from "./DeviceSettings";
import VideoDeviceSettings from "./VideoDeviceSettings";
import DataSharingSettings from "./DataSharingSettings";
import { InternalDrawerState } from "../layouts/internalDrawerStates";
import { isWebRTCAudioEnabled, isVideoEnabled } from "../../utils";
import LocalizedText from "../reusable/LocalizedText";
import { Tabs, Tab } from "@material-ui/core";
import classNames from "classnames";

const styles = theme => ({
  header: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "10px"
  },
  headerText: {
    textAlign: "left",
    marginBottom: "10px"
  },
  hrDiv: {
    width: "100%",
    borderTop: `3px solid ${theme.colors.secondaryMainColor}`
  },
  container: {
    display: "flex",
    zIndex: 11,
    width: "auto",
    height: "auto",
    alignSelf: "center",
    position: "absolute"
  },
  bottomDiv: {
    height: "35px"
  },
  mainContentContainer: {
    display: "flex",
    flexDirection: "column",
    height: "222px"
  },
  largeHeight: {
    height: "453px"
  }
});

class SettingsMenu extends Component {
  render() {
    const { classes, internalDrawer, videoConfig } = this.props;
    const settingsInDrawer = internalDrawer === InternalDrawerState.SETTINGS;

    const component = (
      <Fragment>
        <div className={classes.header}>
          <LocalizedText
            value="userControls"
            variant="h6"
            className={classes.headerText}
          />
          <div className={classes.hrDiv} />
        </div>
        <div
          className={classNames(classes.mainContentContainer, {
            [classes.largeHeight]: isVideoEnabled(videoConfig)
          })}
        >
          {this.getTabControls()}
          {this.getMainView()}
        </div>
        <div className={classes.bottomDiv} />
      </Fragment>
    );

    return (
      <div className={classes.container}>
        <CTXBaseWindow
          component={component}
          overrideHandleClose={() => this.props.hideSettingsMenu()}
          disableClickAwayClose={true}
          disableContainerStyle
          hideHeader={settingsInDrawer}
          className={settingsInDrawer ? classes.layout : undefined}
        />
      </div>
    );
  }

  handleTabChange = (event, tabIndex) => {
    this.props.setControlsTabView(tabIndex);
  };

  getTabControls = () => {
    const { isLoggedIn, controlsTabIndex, userLevel } = this.props;
    const isHost = userLevel === "1";
    return (
      <Tabs
        value={controlsTabIndex}
        onChange={this.handleTabChange}
        textColor="inherit"
        indicatorColor="primary"
      >
        <Tab label={<LocalizedText value="devices" variant="subtitle1" />} />
        {isLoggedIn &&
          (window.CtxAppConfigurations.guestScreenSharingEnabled || isHost) && (
            <Tab
              label={<LocalizedText value="applications" variant="subtitle1" />}
            />
          )}
      </Tabs>
    );
  };

  getMainView = () => {
    const { webRTCCallConfig, videoConfig, controlsTabIndex } = this.props;

    let res = null;
    if (controlsTabIndex === 0) {
      res = (
        <div>
          {isWebRTCAudioEnabled(webRTCCallConfig) && <DeviceSettings />}
          {isVideoEnabled(videoConfig) && <VideoDeviceSettings />}
        </div>
      );
    } else if (controlsTabIndex === 1) {
      res = <DataSharingSettings />;
    }

    return res;
  };
}

const mapStateToProps = state => {
  return {
    controlsTabIndex: state.session.controlsTabIndex,
    internalDrawer: state.internalDrawer,
    webRTCCallConfig: state.session.webRTCCallConfig,
    videoConfig: state.session.videoConfig,
    isLoggedIn: state.session.isLoggedIn,
    userLevel: state.session.userLevel
  };
};

const mapDispatchToProps = dispatch => ({
  hideSettingsMenu: () => dispatch(hideSettingsMenu()),
  setControlsTabView: tabIndex => dispatch(setControlsTabView(tabIndex))
});

export default withTheme(
  withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SettingsMenu))
);
