import React, { Component } from "react";
import { connect } from "react-redux";
import { withTheme } from "@material-ui/core/styles";
import { hideVideoPopout } from "./actions";
import { setMainRoom } from "../../../../actions";
import CtxNewWindow from "../../../NewWindow/CtxNewWindow";
import { Room } from "../../components";
import Peer from "../Peer";
import CTXIconButtonConfirmModal from "../../../reusable/CTXIconButtonConfirmModal";
import TextButton from "../../../reusable/TextButton";

/***
 * If VideoWindowPopoutState.OPEN  ---> PopoutWindow is shown
 * If VideoWindowPopoutState.CLOSE ----> PopoutWindow is closed
 *
 * Stateful component that sets VideoWindowPopoutState.CLOSE in the redux store.
 * When using this, it's upto the implementor to set VideoWindowPopoutState.OPEN in the store.
 * This component reads from the redux store to decide if the popout window should be shown. *
 */

// VideoWindowPopoutState is exported for the reducer to set OPEN and CLOSE states.
export const VideoPopoutState = {
  CLOSE: 1,
  OPEN: 2
};

export const videoPopoutWindowName = "VideoWindow";

const defaultWindowWidth = 1280;
const defaultWindowHeight = defaultWindowWidth / 1.78;
const minWindowWidth = 380;
const minWindowHeight = minWindowWidth / 1.78;
const minWidthCheckTimeout = 360;

let resizeTimer;

class VideoPopoutWindowComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popoutRef: undefined
    };
  }

  ensureMinSize = (width, height, ref) => {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      if (width < minWindowWidth || height < minWindowHeight) {
        ref.window.resizeTo(
          Math.max(minWindowWidth, width),
          Math.max(minWindowHeight, height)
        );
      }
    }, minWidthCheckTimeout);
  };

  handleResize = (window, cssCopied) => {
    if (this.state.popoutRef) {
      this.ensureMinSize(
        this.state.popoutRef.window.outerWidth,
        this.state.popoutRef.window.outerHeight,
        this.state.popoutRef
      );
    }
    // Force the Peers component to re-render once the
    // CSS styles have been copied to the new window.
    if (cssCopied) {
      this.setState({
        windowWidth: 0,
        windowHeight: 0
      });
    }
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    });
  };

  handleUnload = () => {
    const { session } = this.props;
    if (!session.sharingActive) {
      this.props.setMainRoom();
    }

    this.props.hideVideoPopout();
    this.setState({
      ...this.state,
      popoutRef: undefined
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevState.popoutRef && this.popoutRef) {
      this.setState({ ...this.state, popoutRef: this.popoutRef });
    }
  }

  // Force Material-UI to load styles needed in new window
  getClassesForPopout = () => {
    return (
      <div style={{ display: "none" }}>
        <Peer />
        <CTXIconButtonConfirmModal />
        <TextButton>text</TextButton>
      </div>
    );
  };

  render() {
    if (this.props.videoPopoutWindow.windowStatus === VideoPopoutState.OPEN) {
      return (
        <CtxNewWindow
          ref={r => (this.popoutRef = r)}
          onUnload={this.handleUnload}
          resizeCallback={this.handleResize}
          features={{
            width: defaultWindowWidth,
            height: defaultWindowHeight,
            location: 0
          }}
          title="ConnectNow-Video"
          name={videoPopoutWindowName}
          backgroundColor={this.props.theme.popoverBackgroundColor}
        >
          <Room
            windowWidth={this.state.windowWidth}
            windowHeight={this.state.windowHeight}
          />
          {this.getClassesForPopout()}
        </CtxNewWindow>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = ({ videoPopoutWindow, theme, session }) => {
  return {
    videoPopoutWindow: videoPopoutWindow,
    theme: theme,
    session: session
  };
};

const mapDispatchToProps = dispatch => ({
  hideVideoPopout: () => dispatch(hideVideoPopout()),
  setMainRoom: () => dispatch(setMainRoom())
});

export default withTheme(
  connect(mapStateToProps, mapDispatchToProps)(VideoPopoutWindowComponent)
);
