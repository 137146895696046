import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import CTXBaseWindow from "../reusable/CTXBaseWindow";
import { withTheme } from "@material-ui/core/styles";
import { hideNotificationWindow } from "./";
import LocalizedText from "../reusable/LocalizedText";

//NotificationWindowState is exported for the reducer to set OPEN and CLOSE states.
export const NotificationWindowState = {
  CLOSE: 1,
  OPEN: 2
};

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  notificationText: {
    marginTop: "10px",
    marginBottom: "10px",
    maxWidth: "350px"
  }
});

class NotificationWindow extends Component {
  overrideHandleClose = () => {
    this.props.hideNotificationWindow();
  };

  render() {
    const { classes } = this.props;

    const component = (
      <Fragment>
        <LocalizedText
          value={this.props.notification.notificationText}
          variant="body1"
          className={classes.notificationText}
        />
      </Fragment>
    );

    return (
      <Fragment>
        <CTXBaseWindow
          component={component}
          overrideHandleClose={this.overrideHandleClose}
          hideHeader={true}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = ({ notification }) => ({ notification });

const mapDispatchToProps = dispatch => ({
  hideNotificationWindow: () => dispatch(hideNotificationWindow())
});

export default withTheme(
  withStyles(styles)(
    injectIntl(connect(mapStateToProps, mapDispatchToProps)(NotificationWindow))
  )
);
