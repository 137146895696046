import contexWebRest from "../../api/contexWebRest";
import { ServerErrorCode, getPortalUser } from "../../actions";
import {
  showNotificationWindow,
  setNotificationLevel,
  NotificationLevel,
  setNotificationType,
  NotificationType
} from "../notification";
import Logger from "../../Logger";

const logger = new Logger("PortalActionCreators");

export const UserPromptLevel = {
  INFO: 0,
  ERROR: 1,
  SUCCESS: 2
};

//-----------Action types----------//

export const PORTAL_UPDATE_PROFILE_PROMPT = "PORTAL_UPDATE_PROFILE_PROMPT";
export const PORTAL_SETTINGS_SET_MAIN_TAB_INDEX =
  "PORTAL_SETTINGS_SET_MAIN_TAB_INDEX";
export const UPDATE_PORTAL_USER_PERSISTENT_SPACES =
  "UPDATE_PORTAL_USER_PERSISTENT_SPACES";
export const PORTAL_USERMANAGEMENT_SET_TAB_INDEX =
  "PORTAL_USERMANAGEMENT_SET_TAB_INDEX";
export const UPDATE_PORTAL_USERMANAGEMENT_USER_LIST =
  "UPDATE_PORTAL_USERMANAGEMENT_USER_LIST";
export const SET_PORTAL_USERMANAGEMENT_SELECTED_USER =
  "SET_PORTAL_USERMANAGEMENT_SELECTED_USER";
export const SET_PORTAL_USERMANAGEMENT_SELECTED_USERS_ID_LIST =
  "SET_PORTAL_USERMANAGEMENT_SELECTED_USERS_ID_LIST";
export const UPDATE_PORTAL_USERMANAGEMENT_SELECTED_USER_PERSISTENT_SPACE =
  "UPDATE_PORTAL_USERMANAGEMENT_SELECTED_USER_PERSISTENT_SPACE";
export const SET_PORTAL_USERMANAGEMENT_NEW_USER_MODAL_STATUS =
  "SET_PORTAL_USERMANAGEMENT_NEW_USER_MODAL_STATUS";
export const SET_PORTAL_USERMANAGEMENT_NEW_USER_CREATE_STEP =
  "SET_PORTAL_USERMANAGEMENT_NEW_USER_CREATE_STEP";
export const SET_PORTAL_USERMANAGEMENT_NEW_USER_MODAL_USER_PROMPT =
  "SET_PORTAL_USERMANAGEMENT_NEW_USER_MODAL_USER_PROMPT";
export const SET_PORTAL_USERMANAGEMENT_NEW_USER_MODAL_USER_INFORMATION =
  "SET_PORTAL_USERMANAGEMENT_NEW_USER_MODAL_USER_INFORMATION";
export const SET_PORTAL_USERMANAGEMENT_NEW_USER_MODAL_CONF_TYPE =
  "SET_PORTAL_USERMANAGEMENT_NEW_USER_MODAL_CONF_TYPE";
export const SET_PORTAL_USERMANAGEMENT_EXTERNAL_CONFERENCES =
  "SET_PORTAL_USERMANAGEMENT_EXTERNAL_CONFERENCES";
export const SET_PORTAL_USERMANAGEMENT_SELECTED_EXTERNAL_CONFERENCE =
  "SET_PORTAL_USERMANAGEMENT_SELECTED_EXTERNAL_CONFERENCE";
export const SET_PORTAL_USERMANAGEMENT_BUTTON_LOADER =
  "SET_PORTAL_USERMANAGEMENT_BUTTON_LOADER";
export const SET_PORTAL_USERMANAGEMENT_SHOW_DISABLED_CHECKBOX =
  "SET_PORTAL_USERMANAGEMENT_SHOW_DISABLED_CHECKBOX";
export const SET_PORTAL_USERMANAGEMENT_CONFIRM_DELETE_USER_MODAL_STATUS =
  "SET_PORTAL_USERMANAGEMENT_CONFIRM_DELETE_USER_MODAL_STATUS";
export const SET_PORTAL_USER_AUDIO_CONF_DETAILS =
  "SET_PORTAL_USER_AUDIO_CONF_DETAILS";
export const SET_PORTAL_USERMANAGEMENT_PROFILE_SETTING_TAB_INDEX =
  "SET_PORTAL_USERMANAGEMENT_PROFILE_SETTING_TAB_INDEX";
export const SET_PORTAL_USERMANAGEMENT_SELECTED_USER_AUDIO_CONFERENCE =
  "SET_PORTAL_USERMANAGEMENT_SELECTED_USER_AUDIO_CONFERENCE";
export const SET_PORTAL_LICENSE = "SET_PORTAL_LICENSE";
export const SET_PORTAL_LICENSE_MODAL_WINDOW_STATUS =
  "SET_PORTAL_LICENSE_MODAL_WINDOW_STATUS";
export const SET_PORTAL_LICENSE_MODAL_WINDOW_TEXT =
  "SET_PORTAL_LICENSE_MODAL_WINDOW_TEXT";
export const SET_PORTAL_THEMES = "SET_PORTAL_THEMES";
export const SET_PORTAL_SELECTED_THEME_ID = "SET_PORTAL_SELECTED_THEME_ID";
export const SET_THEME_EDITOR_MODAL_STATE = "SET_THEME_EDITOR_MODAL_STATE";
export const SET_THEME_EDITOR_TAB_INDEX = "SET_THEME_EDITOR_TAB_INDEX";
export const SET_GRAPHICS_LIST = "SET_GRAPHICS_LIST";

//---------Action creators-------------//

export const selectPortalSettingTabView = selectedView => {
  return {
    type: PORTAL_SETTINGS_SET_MAIN_TAB_INDEX,
    payload: selectedView
  };
};

export const getPortalUserPersistentSpaces = sessionID => async dispatch => {
  logger.debug(
    "request portal user persistent spaces. sessionId=%d",
    sessionID
  );

  try {
    const response = await contexWebRest.get(
      "/persistentspaces/get_persistent_space/" + sessionID
    );

    if (response.data != null) {
      dispatch({
        type: UPDATE_PORTAL_USER_PERSISTENT_SPACES,
        payload: response.data
      });
    }
  } catch (error) {
    logger.error("Error: %o", error);
  }
};

export const getPortalUserPersistentSpacesByUserID =
  (sessionID, userID) => async dispatch => {
    logger.debug(
      "request portal user persistent spaces by userID. sessionId=%d, userId=%d",
      sessionID,
      userID
    );

    try {
      const response = await contexWebRest.get(
        `/persistentspaces/get_persistent_space_by_user_id/${sessionID}/${userID}`
      );

      if (response && response.data != null) {
        dispatch(setUserManagementSelectedUserPersistentSpace(response.data));
      }
    } catch (error) {
      logger.error("Error: %o", error);

      switch (error.response.data.errorCode) {
        case ServerErrorCode.PERSISTENT_SPACE_NOT_FOUND:
          dispatch(setUserManagementSelectedUserPersistentSpace(undefined));
          break;
        case ServerErrorCode.BAD_REQUEST:
        case ServerErrorCode.INVALID_SESSION:
        case ServerErrorCode.SERVER_ERROR:
        default:
          dispatch(
            setProfileUserPrompt(
              UserPromptLevel.ERROR,
              "failedToRetrievePersistentSpace"
            )
          );
          break;
      }
    }
  };

export const setUserManagementSelectedUserPersistentSpace =
  persistentSpaceDetails => {
    return {
      type: UPDATE_PORTAL_USERMANAGEMENT_SELECTED_USER_PERSISTENT_SPACE,
      payload: persistentSpaceDetails
    };
  };

export const setProfileUserPrompt = (promptLevel, promptContents) => {
  return {
    type: PORTAL_UPDATE_PROFILE_PROMPT,
    payload: {
      profilePromptLevel: promptLevel,
      profilePromptContents: promptContents
    }
  };
};

export const updatePortalUserAccount =
  (sessionID, portalUserObj, userID, selfUserID) => async dispatch => {
    const encodedUsername = encodeURIComponent(portalUserObj.username);
    const encodedPassword = encodeURIComponent(portalUserObj.password);
    const encodedFirstName = encodeURIComponent(portalUserObj.firstName);
    const encodedLastName = encodeURIComponent(portalUserObj.lastName);
    const encodedEmail = encodeURIComponent(portalUserObj.email);

    const uri =
      "/users/updatePortalUser/" +
      sessionID +
      (userID ? "?userID=" + userID : "");
    const requestBody = `username=${encodedUsername}&password=${encodedPassword}&firstname=${encodedFirstName}&lastname=${encodedLastName}&email=${encodedEmail}`;
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };

    try {
      //update user account table
      await contexWebRest.post(uri, requestBody, {
        headers: headers
      });

      dispatch(setProfileUserPrompt(UserPromptLevel.SUCCESS, "profileUpdated"));
      if (userID) {
        dispatch(getPortalUserAccountList(sessionID));
        if (selfUserID === userID) {
          dispatch(getPortalUser(sessionID));
        }
      } else {
        dispatch(getPortalUser(sessionID));
      }
    } catch (error) {
      logger.error("Error: %o", error);

      switch (error.response.data.errorCode) {
        case ServerErrorCode.DUPLICATE_USERNAME:
          dispatch(
            setProfileUserPrompt(UserPromptLevel.ERROR, "duplicateUsername")
          );
          break;
        case ServerErrorCode.BAD_REQUEST:
        case ServerErrorCode.SERVER_ERROR:
        default:
          dispatch(
            setProfileUserPrompt(
              UserPromptLevel.ERROR,
              "failedToUpdateUserProfile"
            )
          );
          break;
      }
    }
  };

export const updatePersistentSpaceName =
  (sessionID, persistentSpaceName) => async dispatch => {
    const encodedPersistentSpaceName = encodeURIComponent(persistentSpaceName);

    const uri = "/persistentspaces/update_persistent_space_name/" + sessionID;
    const requestBody = `name=${encodedPersistentSpaceName}`;
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };

    try {
      //update Persistent Space table
      await contexWebRest.post(uri, requestBody, {
        headers: headers
      });
      dispatch(setProfileUserPrompt(UserPromptLevel.SUCCESS, "profileUpdated"));
      dispatch(getPortalUserPersistentSpaces(sessionID));
    } catch (error) {
      logger.error("Error: %o", error);

      switch (error.response.data.errorCode) {
        case ServerErrorCode.DUPLICATE_PERSISTENT_SPACE:
          dispatch(
            setProfileUserPrompt(
              UserPromptLevel.ERROR,
              "duplicatePersistentSpace"
            )
          );
          break;
        case ServerErrorCode.BAD_REQUEST:
        case ServerErrorCode.SERVER_ERROR:
        default:
          dispatch(
            setProfileUserPrompt(
              UserPromptLevel.ERROR,
              "failedToUpdatePersistentSpace"
            )
          );
          break;
      }
    }
  };

export const updatePersistentSpaceNameByUserID =
  (sessionID, userID, persistentSpaceName) => async dispatch => {
    const encodedPersistentSpaceName = encodeURIComponent(persistentSpaceName);

    const uri =
      "/persistentspaces/update_persistent_space_name_by_user_id/" + sessionID;
    const requestBody = `userID=${userID}&name=${encodedPersistentSpaceName}`;
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };

    try {
      //update Persistent Space table
      await contexWebRest.post(uri, requestBody, {
        headers: headers
      });
      dispatch(setProfileUserPrompt(UserPromptLevel.SUCCESS, "profileUpdated"));
      dispatch(getPortalUserPersistentSpacesByUserID(sessionID, userID));
    } catch (error) {
      logger.error("Error: %o", error);

      switch (error.response.data.errorCode) {
        case ServerErrorCode.DUPLICATE_PERSISTENT_SPACE:
          dispatch(
            setProfileUserPrompt(
              UserPromptLevel.ERROR,
              "duplicatePersistentSpace"
            )
          );
          break;
        case ServerErrorCode.BAD_REQUEST:
        case ServerErrorCode.SERVER_ERROR:
        default:
          dispatch(
            setProfileUserPrompt(
              UserPromptLevel.ERROR,
              "failedToUpdatePersistentSpace"
            )
          );
          break;
      }
    }
  };

export const updatePersistentSpaceSettingsByUserID =
  (
    sessionID,
    userID,
    persistentSpaceName,
    callMeConfig,
    webRTCCallConfig,
    videoConfig,
    disableParticipantListForGuests,
    conferenceRoomControlConfig,
    vettingConfig,
    iframeSource,
    waitingRoomMessage,
    redirectURL,
    transferGroup,
    guestsJoinedInLockedMute,
    maxDisplayedUsers
  ) =>
  async dispatch => {
    const encodedPersistentSpaceName = encodeURIComponent(persistentSpaceName);
    const encodedIframeSource = encodeURIComponent(iframeSource);
    const encodedWaitingRoomMessage = encodeURIComponent(waitingRoomMessage);
    const encodedRedirectURL = encodeURIComponent(redirectURL);
    const encodedTransferGroup = encodeURIComponent(transferGroup);
    const uri =
      "/persistentspaces/update_persistent_space_settings_by_user_id/" +
      sessionID;

    let requestBody = [];
    requestBody.push(
      `userID=${userID}&name=${encodedPersistentSpaceName}&callMeConfig=${callMeConfig}&webRTCCallConfig=${webRTCCallConfig}&vettingConfig=${vettingConfig}&videoConfig=${videoConfig}&disableParticipantListForGuests=${disableParticipantListForGuests}&iframeSource=${encodedIframeSource}&waitingRoomMessage=${encodedWaitingRoomMessage}&redirectURL=${encodedRedirectURL}&transferGroup=${encodedTransferGroup}&conferenceRoomControlConfig=${conferenceRoomControlConfig}&guestsJoinedInLockedMute=${guestsJoinedInLockedMute}`
    );
    if (maxDisplayedUsers != null) {
      requestBody.push(`&maxDisplayedUsers=${maxDisplayedUsers}`);
    }
    requestBody = requestBody.join("");

    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };

    try {
      await contexWebRest.post(uri, requestBody, {
        headers: headers
      });
      dispatch(getPortalUserPersistentSpacesByUserID(sessionID, userID));
      dispatch(getPortalUserPersistentSpaces(sessionID));
      dispatch(setNotificationLevel(NotificationLevel.SUCCESS));
      dispatch(
        setNotificationType(NotificationType.PERSISTENT_SPACE_SETTINGS_UPDATED)
      );
      dispatch(showNotificationWindow());
    } catch (error) {
      logger.error("Error: %o", error);

      switch (error.response.data.errorCode) {
        case ServerErrorCode.DUPLICATE_PERSISTENT_SPACE:
          dispatch(
            setProfileUserPrompt(
              UserPromptLevel.ERROR,
              "duplicatePersistentSpace"
            )
          );
          break;
        case ServerErrorCode.BAD_REQUEST:
        case ServerErrorCode.SERVER_ERROR:
        default:
          dispatch(setNotificationLevel(NotificationLevel.ERROR));
          dispatch(
            setNotificationType(
              NotificationType.PERSISTENT_SPACE_SETTINGS_UPDATED
            )
          );
          dispatch(showNotificationWindow());
          break;
      }
    }
  };

export const selectUserManagementTabView = selectedView => {
  return {
    type: PORTAL_USERMANAGEMENT_SET_TAB_INDEX,
    payload: selectedView
  };
};

export const getPortalUserAccountList = sessionID => async dispatch => {
  logger.debug("request portal user accounts list. sessionId=%d", sessionID);

  try {
    const response = await contexWebRest.get(
      "/users/getPortalUserList/" + sessionID
    );

    if (response && response.data != null && response.data.length > 0) {
      const res = [];

      response.data.forEach(function (value, index, array) {
        res.push({
          userID: value.UserID,
          username: value.UserName,
          userRole: value.UserRole,
          firstName: value.FirstName,
          lastName: value.LastName,
          email: value.Email,
          enabled: value.Enabled
        });
      });

      dispatch({
        type: UPDATE_PORTAL_USERMANAGEMENT_USER_LIST,
        payload: res
      });
    }
  } catch (error) {
    logger.error("Error: %o", error);
  }
};

export const setUserManagementSelectedUser = selectedUser => {
  return {
    type: SET_PORTAL_USERMANAGEMENT_SELECTED_USER,
    payload: selectedUser
  };
};

export const setUserManagementSelectedUserList = selectedUserList => {
  return {
    type: SET_PORTAL_USERMANAGEMENT_SELECTED_USERS_ID_LIST,
    payload: selectedUserList
  };
};

export const setNewUserModalStatus = isOpen => {
  return {
    type: SET_PORTAL_USERMANAGEMENT_NEW_USER_MODAL_STATUS,
    payload: isOpen
  };
};

export const setNewUserModalCreateProgress = number => {
  return {
    type: SET_PORTAL_USERMANAGEMENT_NEW_USER_CREATE_STEP,
    payload: number
  };
};

export const setNewUserModalUserPrompt = (promptLevel, promptContents) => {
  return {
    type: SET_PORTAL_USERMANAGEMENT_NEW_USER_MODAL_USER_PROMPT,
    payload: {
      promptLevel: promptLevel,
      promptContents: promptContents
    }
  };
};

export const setNewUserModalUserInformation = userInfoObj => {
  return {
    type: SET_PORTAL_USERMANAGEMENT_NEW_USER_MODAL_USER_INFORMATION,
    payload: userInfoObj
  };
};

export const setNewUserModalConferenceType = type => {
  return {
    type: SET_PORTAL_USERMANAGEMENT_NEW_USER_MODAL_CONF_TYPE,
    payload: type
  };
};

export const getExternalConferencesList = sessionID => async dispatch => {
  logger.debug("request external conferences list. sessionId=%d", sessionID);

  try {
    const response = await contexWebRest.get(
      "/conference/get_all_external_conferences/" + sessionID
    );

    if (response && response.data != null && response.data.length > 0) {
      const res = [];

      response.data.forEach(function (value, index, array) {
        res.push({
          conferenceID: value.ID,
          conferenceName: value.ConferenceName,
          billingCode: value.BillingCode,
          hostPasscode: value.HostPasscode,
          guestPasscode: value.GuestPasscode
        });
      });

      dispatch({
        type: SET_PORTAL_USERMANAGEMENT_EXTERNAL_CONFERENCES,
        payload: res
      });
    }
  } catch (error) {
    logger.error("Error: %o", error);
  }
};

export const setSelectedExternalConference = conferenceID => {
  return {
    type: SET_PORTAL_USERMANAGEMENT_SELECTED_EXTERNAL_CONFERENCE,
    payload: conferenceID
  };
};

export const clearExternalConferenceList = () => async dispatch => {
  dispatch({
    type: SET_PORTAL_USERMANAGEMENT_EXTERNAL_CONFERENCES,
    payload: undefined
  });
};

export const isUsernamePersistentSpaceExist =
  (sessionID, username, persistentSpaceName) => async dispatch => {
    const encodedUsername = encodeURIComponent(username);
    const uri = "/users/isUserNameExist/" + sessionID;
    const requestBody = `username=${encodedUsername}`;
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };

    try {
      const response = await contexWebRest.post(uri, requestBody, {
        headers: headers
      });

      if (response.data === true) {
        dispatch(setNewUserModalCreateProgress(1));
        dispatch(
          setNewUserModalUserPrompt(UserPromptLevel.ERROR, "duplicateUsername")
        );
        dispatch(setButtonLoader(false));
      } else if (response.data === false) {
        dispatch(isPersistentSpaceExist(sessionID, persistentSpaceName));
      }
    } catch (error) {
      logger.error("Error: %o", error);

      dispatch(
        setNewUserModalUserPrompt(UserPromptLevel.ERROR, "failedToValidateInfo")
      );

      dispatch(setButtonLoader(false));
    }
  };

export const isPersistentSpaceExist = (sessionID, name) => async dispatch => {
  const encodedName = encodeURIComponent(name);
  const uri = "/users/isPersistentSpaceExist/" + sessionID;
  const requestBody = `name=${encodedName}`;
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded"
  };

  try {
    const response = await contexWebRest.post(uri, requestBody, {
      headers: headers
    });

    if (response.data === true) {
      dispatch(setNewUserModalCreateProgress(1));
      dispatch(
        setNewUserModalUserPrompt(
          UserPromptLevel.ERROR,
          "duplicatePersistentSpace"
        )
      );
      dispatch(setButtonLoader(false));
    } else if (response.data === false) {
      dispatch(setNewUserModalCreateProgress(2));
      dispatch(setNewUserModalUserPrompt(UserPromptLevel.INFO, ""));
      dispatch(setButtonLoader(false));
    }
  } catch (error) {
    logger.error("Error: %o", error);

    dispatch(
      setNewUserModalUserPrompt(UserPromptLevel.ERROR, "failedToValidateInfo")
    );
    dispatch(setButtonLoader(false));
  }
};

export const setButtonLoader = enabled => async dispatch => {
  dispatch({
    type: SET_PORTAL_USERMANAGEMENT_BUTTON_LOADER,
    payload: enabled
  });
};

export const createNewUserAccount =
  (sessionID, userObject, persistentSpaceObject, internalConferenceObject) =>
  async dispatch => {
    const encodedUsername = encodeURIComponent(userObject.username);
    const encodedPassword = encodeURIComponent(userObject.password);
    const encodedFirstName = encodeURIComponent(userObject.firstName);
    const encodedLastName = encodeURIComponent(userObject.lastName);
    const encodedEmail = encodeURIComponent(userObject.email);
    const encodedPersistentSpaceName = encodeURIComponent(
      userObject.persistentSpaceName
    );
    const uri = "/users/createNewUserAccount/" + sessionID;
    let requestBody = `username=${encodedUsername}&password=${encodedPassword}&admin=${userObject.isAdmin}&first_name=${encodedFirstName}&last_name=${encodedLastName}&email=${encodedEmail}&enabled=${userObject.enabled}&persistent_space_name=${encodedPersistentSpaceName}&conference_type=${persistentSpaceObject.isInternalConference}`;
    if (persistentSpaceObject.isInternalConference) {
      const encodedInternalConferenceName = encodeURIComponent(
        internalConferenceObject.internalConferenceName
      );
      const encodedInternalHostPasscode = encodeURIComponent(
        internalConferenceObject.internalHostPasscode
      );
      const encodedInternalGuestPasscode = encodeURIComponent(
        internalConferenceObject.internalGuestPasscode
      );
      const encodedInternalBillingCode = encodeURIComponent(
        internalConferenceObject.internalBillingCode
      );
      requestBody =
        requestBody +
        `&internal_conference_name=${encodedInternalConferenceName}&internal_host_passcode=${encodedInternalHostPasscode}&internal_guest_passcode=${encodedInternalGuestPasscode}&internal_billing_code=${encodedInternalBillingCode}`;
    } else {
      requestBody =
        requestBody +
        `&external_conference_id=${persistentSpaceObject.externalConferenceID}`;
    }
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };

    try {
      const response = await contexWebRest.post(uri, requestBody, {
        headers: headers
      });

      if (response.data === true) {
        dispatch(setNewUserModalCreateProgress(3));
        dispatch(setNewUserModalUserPrompt(UserPromptLevel.INFO, ""));
        dispatch(setButtonLoader(false));
        dispatch(getPortalUserAccountList(sessionID));
      } else if (response.data === false) {
        dispatch(setNewUserModalCreateProgress(2));
        dispatch(
          setNewUserModalUserPrompt(
            UserPromptLevel.ERROR,
            "failedToCreateNewUserAccount"
          )
        );
        dispatch(setButtonLoader(false));
      }
    } catch (error) {
      logger.error("Error: %o", error);

      switch (error.response.data.errorCode) {
        case ServerErrorCode.DUPLICATE_USERNAME:
          dispatch(
            setNewUserModalUserPrompt(
              UserPromptLevel.ERROR,
              "duplicateUsername"
            )
          );
          break;
        case ServerErrorCode.DUPLICATE_PERSISTENT_SPACE:
          dispatch(
            setNewUserModalUserPrompt(
              UserPromptLevel.ERROR,
              "duplicatePersistentSpace"
            )
          );
          break;
        case ServerErrorCode.BAD_REQUEST:
        case ServerErrorCode.INVALID_SESSION:
        case ServerErrorCode.SERVER_ERROR:
        default:
          dispatch(
            setNewUserModalUserPrompt(
              UserPromptLevel.ERROR,
              "failedToCreateNewUserAccount"
            )
          );
          break;
      }

      dispatch(setButtonLoader(false));
    }
  };

export const activateDisabledUserAccount =
  (
    sessionID,
    userID,
    persistentSpaceName,
    persistentSpaceObject,
    internalConferenceObject
  ) =>
  async dispatch => {
    const encodedPersistentSpaceName = encodeURIComponent(persistentSpaceName);
    const uri = "/users/activatePortalUserAccount/" + sessionID;
    let requestBody = `userID=${userID}&persistent_space_name=${encodedPersistentSpaceName}&conference_type=${persistentSpaceObject.isInternalConference}`;
    if (persistentSpaceObject.isInternalConference) {
      const encodedInternalConferenceName = encodeURIComponent(
        internalConferenceObject.internalConferenceName
      );
      const encodedInternalHostPasscode = encodeURIComponent(
        internalConferenceObject.internalHostPasscode
      );
      const encodedInternalGuestPasscode = encodeURIComponent(
        internalConferenceObject.internalGuestPasscode
      );
      const encodedInternalBillingCode = encodeURIComponent(
        internalConferenceObject.internalBillingCode
      );
      requestBody =
        requestBody +
        `&internal_conference_name=${encodedInternalConferenceName}&internal_host_passcode=${encodedInternalHostPasscode}&internal_guest_passcode=${encodedInternalGuestPasscode}&internal_billing_code=${encodedInternalBillingCode}`;
    } else {
      requestBody =
        requestBody +
        `&external_conference_id=${persistentSpaceObject.externalConferenceID}`;
    }
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };

    try {
      const response = await contexWebRest.post(uri, requestBody, {
        headers: headers
      });

      if (response.data === true) {
        dispatch(setNewUserModalCreateProgress(3));
        dispatch(setNewUserModalUserPrompt(UserPromptLevel.INFO, ""));
        dispatch(setButtonLoader(false));
        dispatch(getPortalUserAccountList(sessionID));
      } else if (response.data === false) {
        dispatch(setNewUserModalCreateProgress(2));
        dispatch(
          setNewUserModalUserPrompt(
            UserPromptLevel.ERROR,
            "failedToActivateUserAccount"
          )
        );
        dispatch(setButtonLoader(false));
      }
    } catch (error) {
      logger.error("Error: %o", error);

      switch (error.response.data.errorCode) {
        case ServerErrorCode.DUPLICATE_PERSISTENT_SPACE:
          dispatch(
            setNewUserModalUserPrompt(
              UserPromptLevel.ERROR,
              "duplicatePersistentSpace"
            )
          );
          break;
        case ServerErrorCode.BAD_REQUEST:
        case ServerErrorCode.INVALID_SESSION:
        case ServerErrorCode.SERVER_ERROR:
        default:
          dispatch(
            setNewUserModalUserPrompt(
              UserPromptLevel.ERROR,
              "failedToActivateUserAccount"
            )
          );
          break;
      }

      dispatch(setButtonLoader(false));
    }
  };

export const disableUsers =
  (sessionID, disabledUsersArray) => async dispatch => {
    const uri = "/users/disablePortalUserAccount/" + sessionID;

    let userIDs = "";

    if (disabledUsersArray instanceof Array) {
      for (let i = 0; i < disabledUsersArray.length; i++) {
        userIDs += "userID[]=" + disabledUsersArray[i];
        if (i < disabledUsersArray.length - 1) {
          userIDs += "&";
        }
      }
    } else {
      userIDs = "userID[]=" + disabledUsersArray;
    }

    const requestBody = `sessionID=${sessionID}&${userIDs}`;
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };

    try {
      await contexWebRest.post(uri, requestBody, {
        headers: headers
      });

      dispatch(getPortalUserAccountList(sessionID));
      dispatch(setProfileUserPrompt(UserPromptLevel.INFO, ""));
    } catch (error) {
      logger.error("Error: %o", error);

      if (error.response.data.errorCode === ServerErrorCode.SELF_DELETE) {
        dispatch(
          setProfileUserPrompt(UserPromptLevel.ERROR, "selfDeleteError")
        );
      } else {
        dispatch(setProfileUserPrompt(UserPromptLevel.ERROR, "unknownError"));
      }
    }
  };

export const setShowDisabledUserCheckBox = isEnabled => async dispatch => {
  dispatch({
    type: SET_PORTAL_USERMANAGEMENT_SHOW_DISABLED_CHECKBOX,
    payload: isEnabled
  });
};

export const setConfirmDeleteUserModalStatus = isVisible => async dispatch => {
  dispatch({
    type: SET_PORTAL_USERMANAGEMENT_CONFIRM_DELETE_USER_MODAL_STATUS,
    payload: isVisible
  });
};

export const getPortalUserAudioConferenceDetails =
  sessionID => async dispatch => {
    logger.debug(
      "request portal user audio conference details. sessionId=%d",
      sessionID
    );

    try {
      const response = await contexWebRest.get(
        "/conference/getPortalUserAudioConference/" + sessionID
      );

      if (response && response.data != null) {
        dispatch({
          type: SET_PORTAL_USER_AUDIO_CONF_DETAILS,
          payload: {
            ConferenceID: response.data.ID,
            ConferenceName: response.data.ConferenceName,
            BillingCode: response.data.BillingCode,
            HostPasscode: response.data.HostPasscode,
            GuestPasscode: response.data.GuestPasscode
          }
        });
      }
    } catch (error) {
      logger.error("Error: %o", error);
    }
  };

export const getPortalUserAudioConferenceDetailsAdmin =
  (sessionID, dbUserID) => async dispatch => {
    logger.debug(
      "request portal user audio conference details. sessionId=%d dbUserID=%d",
      sessionID,
      dbUserID
    );

    try {
      const response = await contexWebRest.get(
        "/conference/getPortalUserAudioConference/" +
          sessionID +
          "?dbUserID=" +
          dbUserID
      );

      if (response && response.data != null) {
        dispatch(setUserManagementSelectedUserAudioConference(response.data));
      }
    } catch (error) {
      logger.error("Error: %o", error);
    }
  };

export const setUserManagementProfileSettingsTabIndex = selectedView => {
  return {
    type: SET_PORTAL_USERMANAGEMENT_PROFILE_SETTING_TAB_INDEX,
    payload: selectedView
  };
};

export const setUserManagementSelectedUserAudioConference =
  audioConferenceDetails => {
    return {
      type: SET_PORTAL_USERMANAGEMENT_SELECTED_USER_AUDIO_CONFERENCE,
      payload: audioConferenceDetails
    };
  };

export const getConnectNowLicenses = sessionID => async dispatch => {
  logger.debug("request ConnectNow Licenses. sessionId=%d", sessionID);

  try {
    const response = await contexWebRest.get("/license/" + sessionID);

    if (response && response.data != null) {
      dispatch({
        type: SET_PORTAL_LICENSE,
        payload: response.data
      });
    }
  } catch (error) {
    logger.error("Error: %o", error);
  }
};

export const updateConnectNowLicenses =
  (sessionID, licenseKey, licenseValue) => async dispatch => {
    logger.debug(
      "request to update ConnectNow License. sessionId=%d",
      sessionID
    );
    const encodedLicenseKey = encodeURIComponent(licenseKey);
    const encodedLicenseValue = encodeURIComponent(licenseValue);
    const uri = "/license/" + sessionID + "/update";
    const requestBody = `licenseKey=${encodedLicenseKey}&licenseValue=${encodedLicenseValue}`;
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };

    try {
      await contexWebRest.post(uri, requestBody, {
        headers: headers
      });
      dispatch(getConnectNowLicenses(sessionID));
      dispatch(setLicenseModalText("licenseHasBeenUpdated"));
      dispatch(setLicenseModalStatus(true));
    } catch (error) {
      logger.error("Failed to update ConnectNow license. %o", error);
      dispatch(setLicenseModalText("unableToUpdate"));
      dispatch(setLicenseModalStatus(true));
    }
  };

export const setLicenseModalStatus = isOpen => {
  return {
    type: SET_PORTAL_LICENSE_MODAL_WINDOW_STATUS,
    payload: isOpen
  };
};

export const setLicenseModalText = text => {
  return {
    type: SET_PORTAL_LICENSE_MODAL_WINDOW_TEXT,
    payload: text
  };
};

export const insertConnectNowLicenses =
  (sessionID, licenseKey, licenseValue) => async dispatch => {
    logger.debug(
      "request to insert ConnectNow License. sessionId=%d",
      sessionID
    );
    const encodedLicenseKey = encodeURIComponent(licenseKey);
    const encodedLicenseValue = encodeURIComponent(licenseValue);
    const uri = "/license/" + sessionID + "/insert";
    const requestBody = `licenseKey=${encodedLicenseKey}&licenseValue=${encodedLicenseValue}`;
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };

    try {
      await contexWebRest.post(uri, requestBody, {
        headers: headers
      });
      dispatch(getConnectNowLicenses(sessionID));
      dispatch(setLicenseModalText("licenseHasBeenUpdated"));
      dispatch(setLicenseModalStatus(true));
    } catch (error) {
      logger.error("Failed to insert ConnectNow license. %o", error);
      dispatch(setLicenseModalText("unableToUpdate"));
      dispatch(setLicenseModalStatus(true));
    }
  };

export const removeConnectNowLicenses =
  (sessionID, licenseKey) => async dispatch => {
    logger.debug(
      "request to remove ConnectNow License. sessionId=%d",
      sessionID
    );
    const encodedLicenseKey = encodeURIComponent(licenseKey);
    const uri = "/license/" + sessionID + "/remove";
    const requestBody = `licenseKey=${encodedLicenseKey}`;
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };

    try {
      await contexWebRest.post(uri, requestBody, {
        headers: headers
      });
      dispatch(getConnectNowLicenses(sessionID));
      dispatch(setLicenseModalText("licenseHasBeenRemoved"));
      dispatch(setLicenseModalStatus(true));
    } catch (error) {
      logger.error("Failed to remove ConnectNow license. %o", error);
      dispatch(setLicenseModalText("unableToRemove"));
      dispatch(setLicenseModalStatus(true));
    }
  };

export const setThemeEditorModalState = payload => {
  return {
    type: SET_THEME_EDITOR_MODAL_STATE,
    payload
  };
};

export const getThemes = sessionID => async dispatch => {
  logger.debug("request all themes: %d", sessionID);

  try {
    const response = await contexWebRest.get("/theme/" + sessionID);
    dispatch({
      type: SET_PORTAL_THEMES,
      payload: response.data
    });
  } catch (error) {
    logger.error("Error: %o", error);
  }
};

export const updateDefaultTheme =
  (sessionID, themeID, value) => async dispatch => {
    logger.debug(
      "updateDefaultTheme for themeID: %d to value: %s for sessionID: %d",
      themeID,
      value,
      sessionID
    );
    const uri = "/theme/update_default_theme/" + sessionID;
    const requestBody = `themeID=${themeID}&value=${value}`;
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };
    try {
      await contexWebRest.post(uri, requestBody, {
        headers: headers
      });
      dispatch(getThemes(sessionID));
      dispatch(setNotificationLevel(NotificationLevel.SUCCESS));
      dispatch(setNotificationType(NotificationType.GLOBAL_THEME_UPDATED));
      dispatch(showNotificationWindow());
    } catch (error) {
      logger.error("Error: %o", error);
      dispatch(setNotificationLevel(NotificationLevel.ERROR));
      dispatch(setNotificationType(NotificationType.GLOBAL_THEME_UPDATED));
      dispatch(showNotificationWindow());
    }
  };

export const updateThemeProperties =
  (
    sessionID,
    themeID,
    color1,
    color2,
    color3,
    color4,
    backgroundImage,
    headerLogo,
    bodyLogo,
    portalLogo,
    portalHeaderLogo
  ) =>
  async dispatch => {
    logger.debug(
      "updateThemeProperties for themeID: %d for sessionID: %d",
      themeID,
      sessionID
    );
    const uri = "/theme/update_theme_properties/" + sessionID;
    const encodedColor1 = encodeURIComponent(color1);
    const encodedColor2 = encodeURIComponent(color2);
    const encodedColor3 = encodeURIComponent(color3);
    const encodedColor4 = encodeURIComponent(color4);
    const encodedBackgroundImage = encodeURIComponent(backgroundImage);
    const encodedHeaderLogo = encodeURIComponent(headerLogo);
    const encodedBodyLogo = encodeURIComponent(bodyLogo);
    const encodedPortalLogo = encodeURIComponent(portalLogo);
    const encodedPortalHeaderLogo = encodeURIComponent(portalHeaderLogo);
    const requestBody = `themeID=${themeID}&color1=${encodedColor1}&color2=${encodedColor2}&color3=${encodedColor3}&color4=${encodedColor4}&backgroundImage=${encodedBackgroundImage}&headerLogo=${encodedHeaderLogo}&bodyLogo=${encodedBodyLogo}&portalLogo=${encodedPortalLogo}&portalHeaderLogo=${encodedPortalHeaderLogo}`;
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };
    try {
      await contexWebRest.post(uri, requestBody, {
        headers: headers
      });
      dispatch(getThemes(sessionID));
      dispatch(setNotificationLevel(NotificationLevel.SUCCESS));
      dispatch(setNotificationType(NotificationType.THEME_PROPERTIES_UPDATED));
      dispatch(showNotificationWindow());
    } catch (error) {
      logger.error("Error: %o", error);
      dispatch(setNotificationLevel(NotificationLevel.ERROR));
      dispatch(setNotificationType(NotificationType.THEME_PROPERTIES_UPDATED));
      dispatch(showNotificationWindow());
    }
  };

export const addNewTheme =
  (
    sessionID,
    themeName,
    color1,
    color2,
    color3,
    color4,
    isDefault,
    backgroundImage,
    headerLogo,
    bodyLogo,
    portalLogo,
    portalHeaderLogo
  ) =>
  async dispatch => {
    logger.debug(
      "addNewTheme for themeName: %s for sessionID: %d",
      themeName,
      sessionID
    );
    const uri = "/theme/add_theme/" + sessionID;
    const encodedColor1 = encodeURIComponent(color1);
    const encodedColor2 = encodeURIComponent(color2);
    const encodedColor3 = encodeURIComponent(color3);
    const encodedColor4 = encodeURIComponent(color4);
    const encodedBackgroundImage = encodeURIComponent(backgroundImage);
    const encodedHeaderLogo = encodeURIComponent(headerLogo);
    const encodedBodyLogo = encodeURIComponent(bodyLogo);
    const encodedPortalLogo = encodeURIComponent(portalLogo);
    const encodedPortalHeaderLogo = encodeURIComponent(portalHeaderLogo);

    const requestBody = `themeName=${themeName}&color1=${encodedColor1}&color2=${encodedColor2}&color3=${encodedColor3}&color4=${encodedColor4}&isDefault=${isDefault}&backgroundImage=${encodedBackgroundImage}&headerLogo=${encodedHeaderLogo}&bodyLogo=${encodedBodyLogo}&portalLogo=${encodedPortalLogo}&portalHeaderLogo=${encodedPortalHeaderLogo}`;
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };
    try {
      const response = await contexWebRest.post(uri, requestBody, {
        headers: headers
      });
      dispatch(getThemes(sessionID));
      dispatch(setPortalSelectedThemeId(response.data));
      dispatch(setNotificationLevel(NotificationLevel.SUCCESS));
      dispatch(setNotificationType(NotificationType.THEME_ADDED));
      dispatch(showNotificationWindow());
    } catch (error) {
      logger.error("Error: %o", error);
      dispatch(setNotificationLevel(NotificationLevel.ERROR));
      dispatch(setNotificationType(NotificationType.THEME_ADDED));
      dispatch(showNotificationWindow());
    }
  };

export const setPortalSelectedThemeId = themeId => {
  return {
    type: SET_PORTAL_SELECTED_THEME_ID,
    payload: { themeId: themeId }
  };
};

export const updatePersistentSpaceThemeID =
  (sessionID, userID, themeID) => async dispatch => {
    logger.debug(
      "updatePersistentSpaceThemeID for sessionID: %d for userID: %d to themeID: %d",
      sessionID,
      userID,
      themeID
    );
    const uri =
      "/persistentspaces/update_persistent_space_theme_id/" + sessionID;
    const requestBody = `userID=${userID}&themeID=${themeID}`;
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };
    try {
      await contexWebRest.post(uri, requestBody, {
        headers: headers
      });
      dispatch(getPortalUserPersistentSpacesByUserID(sessionID, userID));
      dispatch(getPortalUserPersistentSpaces(sessionID));
      dispatch(setNotificationLevel(NotificationLevel.SUCCESS));
      dispatch(
        setNotificationType(NotificationType.PERSISTENT_SPACE_THEME_UPDATED)
      );
      dispatch(showNotificationWindow());
    } catch (error) {
      logger.error("Error: %o", error);
      dispatch(setNotificationLevel(NotificationLevel.ERROR));
      dispatch(
        setNotificationType(NotificationType.PERSISTENT_SPACE_THEME_UPDATED)
      );
      dispatch(showNotificationWindow());
    }
  };

export const uploadImage = (sessionID, formData) => async dispatch => {
  const uri = "/theme/upload_image/" + sessionID;
  logger.debug("uploadImage request uri: %s", uri);

  try {
    await contexWebRest.post(uri, formData);
    dispatch(setNotificationLevel(NotificationLevel.SUCCESS));
    dispatch(setNotificationType(NotificationType.FILE_UPLOADED));
    dispatch(showNotificationWindow());
  } catch (error) {
    logger.error("Error: %o", error);
    dispatch(setNotificationLevel(NotificationLevel.ERROR));
    dispatch(setNotificationType(NotificationType.FILE_UPLOADED));
    dispatch(showNotificationWindow());
  }
};

export const setThemeEditorTabIndex = tabIndex => {
  return {
    type: SET_THEME_EDITOR_TAB_INDEX,
    payload: tabIndex
  };
};

export const getGraphicsList = sessionID => async dispatch => {
  logger.debug("request GraphicsList: %d", sessionID);
  const uri = "/theme/get_directory_listing/" + sessionID;

  try {
    const response = await contexWebRest.get(uri);
    dispatch({
      type: SET_GRAPHICS_LIST,
      payload: response.data
    });
  } catch (error) {
    logger.error("Error: %o", error);
  }
};
